import {connect} from "react-redux";
import React from "react";
import {
    arrayOfJSONsToJSON,
    col10,
    col12,
    col2,
    col4,
    col8,
    getStorageObject,
    notify,
    readFromLocalStorage,
    row
} from "../../../MiscUtils";

import ListOfPayments from "../../interfaces/user-ui/representative/ListOfPayments";
import SideBar from "../side-bar/SideBar";
import GMS from "../../parent-component/GMS";
import {Dispatch} from "../redux/app-storage";
import {
    APPLICATION_STATUS,
    APPLICATION_TYPE,
    BANKS,
    BCLB,
    COMPANY_POSITIONS,
    COUNTRY,
    COUNTY,
    GAME,
    GAMES_PRIZE_CATEGORY,
    GENDER,
    HARDWAREDEVICES,
    NOTIFICATIONS,
    OPERATOR,
    PREV_ROUTE,
    REASON_FOR_LICENSE,
    SECTIONS,
    SHAREHOLDER_TYPE,
    SOFTWAREDEVICES,
    SUBTITLE
} from "../redux/allowed-actions";
import MenuList from "../../general-features/menu/MenuList";
import {SideMenuCallbacks} from "../../general-features/menu/callbacks/MenuCallbacksAndActions";
import OperatorView from "../../rotm-custom-views/operator/OperatorView";
import LicenseApplication from "../../interfaces/component-input-fields/license-applications/LicenseApplication";
import OperatorsList from "../../rotm-custom-views/operator/OperatorsList";
import RepresentativesList from "../../rotm-custom-views/operator/RepresentativesList";
import OperatorRegistration from "../../interfaces/component-input-fields/operator-declaration/OperatorRegistration";
import CreateNewIssue from "../../rotm-custom-views/issues/CreateNewIssue";
import ListIssues from "../../rotm-custom-views/issues/ListIssues";

import "./dashboard.css";
import LicensePermitsList from "../../rotm-custom-views/licenses-permits/LicensePermitsList";
import ClaimedPaymentView from "../../interfaces/user-ui/finance/ClaimedPaymentView";
import LicensePermitView from "../../rotm-custom-views/licenses-permits/LicensePermitView";
import Notification from "../notification-component/Notification";
import DefaultDashboardList
    from "../../interfaces/user-ui/shared-views/licensor-section-categories/DefaultDashboardList";
import ShareholderView from "../../interfaces/user-ui/representative/shareholders/ShareholderView";
import OperatorShareHoldersSection
    from "../../interfaces/component-input-fields/operator-declaration/operator-collector-sections/OperatorShareHoldersSection";
import ShareholdersList from "../../interfaces/user-ui/representative/shareholders/ShareholdersList";
import ListDocuments from "../document-viewer/ListDocuments";
import DocumentDetailsView from "../document-viewer/DocumentDetailsView";
import ApplicationDocumentsUploadFields
    from "../../interfaces/component-input-fields/license-applications/field-sets/ApplicationDocumentsUploadFields";
import IssueView from "../../rotm-custom-views/issues/IssueView";
import FileReturns from "../../rotm-custom-views/returns/FileReturns";
import MessagingComponent from "../chat-component/MessagingComponent";
import NotificationsList from "../notification-component/NotificationsList";
import NotificationView from "../notification-component/NotificationView";
import Avatar from "../../profile/profile-collection-view-sections/Avatar";
import ContactInfoUpdater from "../../profile/profile-collection-view-sections/ContactInfoUpdator";
import PasswordUpdater from "../../profile/profile-collection-view-sections/PasswordUpdator";
import ViewOperatorProfile from "../../profile/profile-collection-view-sections/ViewOperatorProfile";
import Logout from "../../profile/logout-events/Logout";
import Profile from "../../profile/Profile";
import CorrespondencesList from "../../rotm-custom-views/correspondences/CorrespondencesList";
import LetterComposer from "../../rotm-custom-views/operator/LetterComposer";
import BCLBLetterHead from "../../rotm-custom-views/operator/BCLBLetterHead";
import CorrespondenceView from "../../rotm-custom-views/correspondences/CorrespondenceView";
import UpdateShareholdersList from "../../interfaces/user-ui/representative/shareholders/UpdateShareholdersList";
import {Outlet, Route, Routes} from "react-router-dom";
import PermitApplication from "../../interfaces/component-input-fields/license-applications/PermitApplication";
import OutletApplication from "../../interfaces/component-input-fields/premises/OutletApplication";
import DeclarePremise from "../../interfaces/user-ui/representative/premise-components/DeclarePremise";
import FaQInternal from "../../interfaces/others/FaQInternal";
import ContactInfo from "../../interfaces/others/ContactInfo";
import UserManual from "../../interfaces/others/UserManual";
import Glossary from "../../interfaces/others/Glossary";
import OddsProviderSection
    from "../../interfaces/component-input-fields/operator-declaration/operator-collector-sections/OddsProviderSection";
import OperatorEmployeeSection
    from "../../interfaces/component-input-fields/operator-declaration/operator-collector-sections/OperatorEmployeeSection";

import EmployeeList from "../../interfaces/user-ui/shared-views/employees-viewer/EmployeeList";
import PremiseList from "../../interfaces/user-ui/representative/premises/PremiseList";
import PremiseView from "../../interfaces/user-ui/representative/premises/PremiseView";
import EmployeeView from "../../interfaces/user-ui/shared-views/employees-viewer/EmployeeView";
import PDFDocument from "../../interfaces/document-printer/PDFDocument";
import LicenseRenewal from "../../interfaces/component-input-fields/license-applications/LicenseRenewal";
import ListFileReturns from "../../rotm-custom-views/returns/ListFileReturns";
import DeclareBOF from "../../rotm-custom-views/bof/DeclareBOF";
import ListBOFMembers from "../../rotm-custom-views/bof/ListBOFMembers";
import DeclarePaymentGateway from "../../rotm-custom-views/payment-gateways/DeclarePaymentGateway";
import ListGateways from "../../rotm-custom-views/payment-gateways/ListGateways";
import LicenseRenewalsList from "../../rotm-custom-views/licenses-permits/LicenseRenewalsList";
import OddsProvidersList from "../../interfaces/user-ui/representative/odds-providers/OddsProvidersList";
import GatewayView from "../../rotm-custom-views/payment-gateways/GatewayView";
import PaginatedPremises from "../../interfaces/paginated-views/PaginatedPremises";

/**
 *
 * This component represents the default dashboard that's employed by all users,
 * except officers (because the dash will be had for tablet use
 *
 * It presents a title bar, profile section, work area and side menu
 * (which hides on screen-size change, using css magic)
 */
class DashBoard extends GMS {
    constructor(props) {
        super(props);
        this.role = readFromLocalStorage('role', BCLB);
    }

    /**
     * This property allows the setting of mandatory fields in context of the LicenseApplication
     * component that routes to components_to_delete within its route hierarchy
     */
    setMandatoryFields = (mandatoryFields) => {
        this.setState({mandatoryFields: null}, () => {
            this.setState({mandatoryFields: mandatoryFields})
        });
    }
    /**
     *
     * Allow storage of file data in the Dashboard component
     *
     * @param source
     * @param data data to store
     *
     */
    bufferStateData = (source, data) => {
        //console.log('BUFFERDATA data is ', data)
        let k = 0;
        let q = this.state.dataItem3;
        let isPresent = false;
        let e = {};
        e[source] = data;
        if (this.state.dataItem3.length === 0) {
            q[0] = e;
            // this.setState({dataItem3: [e]});
        } else {
            // convert the array into an arr y of jsons
            const aOJ = arrayOfJSONsToJSON(q);
            //console.log('aOJ ', aOJ)
            if (Object.getOwnPropertyNames(aOJ).includes(source)) {
                do {
                    //console.log(source, Object.getOwnPropertyNames(this.state.dataItem3[k])[0], source === Object.getOwnPropertyNames(this.state.dataItem3[k])[0])
                    if (source === Object.getOwnPropertyNames(this.state.dataItem3[k])[0]) {
                        // check the values
                        q[k] = e;
                        isPresent = true;
                        break;
                    }
                    k += 1;
                } while (k < this.state.dataItem3);
            } else
                q.push(e)

        }
        // if data is present, do nothing. Data has been updated
        // else append/push the new data item into
        // check if data is an array. If it is,
        // if (isPresent === false)
        //     // only update it
        //     q.push(e);
        this.setState({dataItem3: [...q]}, () => {
            //console.log('STATE--BUFFERDATA data is ', this.state.dataItem3);
        });
    }
    /**
     *
     * Update profile component
     *
     * @param nextProps
     * @param nextContext
     * @constructor
     *
     */
    UNSAFE_componentWillReceiveProps = (nextProps, nextContext) => {
        // set country, county, bank, gender, email
        this.setState({
            countries: nextProps.country,
            counties: nextProps.county,
            gender: nextProps.gender,
            banks: nextProps.banks,
            email: nextProps.email,
            role: nextProps.role,
            miscellaneousItems: nextProps.operator
        });
    }
    /**
     *
     * Query for notifications from the backend specifically those that are unread. If they are read, do not
     * show them
     *
     */
    queryNotifications = async (doNotShowMessaage = false) => {
        this.sendRequest('get', 'notifications/unread', notifications => {
            if (notifications.priority === 4) {
                // if (!doNotShowMessaage) {
                //     notify(notifications.message, 1, true);
                // }
                Dispatch({type: NOTIFICATIONS, payload: notifications.data});
                this.setState({dataItem1: [...notifications.data]});
            }
        }, {});
    }
    /**
     *
     * updates the sidebar menu items
     * MenuItems list and callbacks
     * MenuList structure
     * [
     *      {
     *          name: menu_name1,
     *          menu:{
     *              submenu1:
     *                  {
     *                      icon: BLOB1_A,
     *                      callback: callback
     *                  },
     *              submenu2:
     *                  {
     *                      icon: BLOB1_B,
     *                      callback: callback
     *                  },
     *                  ...
     *          },
     *          icon: BLOB1,
     *          callback: CALLBACK
     *      },
     *      ...
     * }]
     *
     * @param loadMenuFromRole
     * @param operator
     */
    updateSideBar = (loadMenuFromRole = this.props.role, operator = []) => {
        //console.log('update sidebar ', operator)
        // instantiate a menu callbacks list for the role in question
        const menuListCallbacks = new SideMenuCallbacks(this);
        //console.log(' menu list callbacks ', menuListCallbacks)
        //console.log('operator ', operator, 'operator status ',operator[0].status)
        const contextualMenuCallbacks = menuListCallbacks.getAppropriateMenuCallbacks(loadMenuFromRole)(operator.length > 0,
            operator.length > 0 && operator[0].status === 'Active');
        //console.log(contextualMenuCallbacks)
        // check if role has an operator. If they do not have, only show the declare operator menu
        this.setState({sideBar: null}, () => {
            let disableMenu = [];
            this.setState({
                // if you are an operator and you have an operator, disable
                sideBar: <SideBar
                    disableMenu={disableMenu}
                    menuList={MenuList.getAppropriateMenu(loadMenuFromRole,
                        contextualMenuCallbacks,
                        operator.length > 0 ?
                            operator[0].status : null)}/>
            });
        });
        // read from local storage
        const k = readFromLocalStorage('previous_route', BCLB);
        // set the default render for the people in question
        // licensor, legal, inspection (though change this later)
        // create a regexp for testing out view
        if (loadMenuFromRole === 4)
            this.navigateTo(k ? k :
                '../list-operators', false);
        else {
            this.navigateTo('../logout', true);
            notify('cannot log you in!', 2, true);
        }
        // make sure current route is remembered
        // Dispatch({type: PREV_ROUTE, payload: k});
    }
    /**
     *
     * load data or do something along the lines of preparations
     *
     */
    componentDidMount = () => {
        const state = getStorageObject(BCLB);
        this.sendRequest('get',
            `account/user`,
            user => {
                if (user.type === 'Success')
                    this.setState({dataItem2: [...user.data]})
                else if (user.detail) { // this is an error and the person has to be logged out
                    // navigate to logout
                    this.navigateTo('logout');
                }
            },
            {});
        // check previous route and
        state.previous_route && this.navigateTo(`${state.previous_route}`);
        this.sendRequest('get', 'company/operator', response => {
            if (response.status === 403) {
                // navigate to logout
                this.navigateTo('./logout', true);
            }
        }, {});
        if (state.email === null || state.email === undefined) {
            //console.log(' there is a problem with logged in user email. was it reset? ');
            notify('The system logged you out due to an unknown error.' +
                ' No worries, Kindly log in again', 2, true);
            this.navigateTo('../representative', true);
        } else {
            this.queryNotifications().then(r => {
            });
            Dispatch({type: SUBTITLE, payload: `Welcome ${state.email.split('@')[0]}!`})
            if (state.role) {
                this.sendRequest("get", 'company/operator', operator => {
                    Dispatch({type: OPERATOR, payload: operator.data});
                    this.updateSideBar(state.role, operator.data); // allow sidebar menu to be updated with the relevant menu
                });
                this.sendRequest('get', 'selections/game', games => {
                    Dispatch({type: GAME, payload: games.data});
                });
                this.sendRequest('get', 'selections/applicationtype', application_types => {
                    Dispatch({type: APPLICATION_TYPE, payload: application_types.data});
                });
                this.sendRequest('get', 'selections/reasonlicense', reasons => {
                    Dispatch({type: REASON_FOR_LICENSE, payload: reasons.data});
                })
                // check if the country, county, bank and gender items have been loaded...?
                this.sendRequest('get', 'selections/country', country => {
                    Dispatch({type: COUNTRY, payload: country.data})
                });
                this.sendRequest('get', 'selections/county', county => {
                    Dispatch({type: COUNTY, payload: county.data})
                });
                this.sendRequest('get', 'selections/bank', banks => {
                    Dispatch({type: BANKS, payload: banks.data})
                });
                // this endpoint is absent
                // this.sendRequest('get', 'selections/bankbranch', branches => {
                //     Dispatch({type: BRANCHES, payload: branches.data})
                // });
                // this.sendRequest('get', 'selections/bank', banks => {
                //     Dispatch({type: BANKS, payload: banks.data})
                // });
                this.sendRequest("get", "selections/sections", sections => {
                    Dispatch({type: SECTIONS, payload: sections.data});
                });
                // get applicable fees
                // this.sendRequest("get", "selections/feetypes", fee_types => {
                //     Dispatch({type: FEE_TYPE, payload: fee_types.data});
                // });
                this.sendRequest("get", "selections/companypositions", (data) => {
                    //update local state with this data in the genderList
                    Dispatch({type: COMPANY_POSITIONS, payload: data.data});
                });
                this.sendRequest('get', 'selections/applicationstatus', application_status => {
                    Dispatch({type: APPLICATION_STATUS, payload: application_status.data});
                });
                this.sendRequest('get', 'selections/prizecategory', (data) => {
                    Dispatch({type: GAMES_PRIZE_CATEGORY, payload: data.data});
                });
                this.sendRequest('get', 'selections/gender', (data) => {
                    Dispatch({type: GENDER, payload: data.data});
                });
                this.sendRequest('get', 'selections/shareholdertype', (data) => {
                    Dispatch({type: SHAREHOLDER_TYPE, payload: data.data});
                });

                this.sendRequest('get', 'selections/hardwaredevicetype', data => {
                    Dispatch({type: HARDWAREDEVICES, payload: data.data});
                }, {});
                this.sendRequest('get', 'selections/softwaredevicetype', data => {
                    Dispatch({type: SOFTWAREDEVICES, payload: data.data});
                }, {});
                //
            } else
                this.navigateTo('/representative', true);
        }
    }
    /**
     *
     * Draw profile notification
     * @returns {JSX.Element}
     *
     */

    drawProfileNotification = () => {
        return <div className={`${col2} menu-item-column`}>
            <div className={row}>
                <div
                    className={`${col12} tab-menu`}
                    onClick={(e) => {
                        this.switchSelection(e);
                        this.switchProfile(<Avatar/>);
                        Dispatch({type: SUBTITLE, payload: 'manage my current avatar'});
                    }}
                >
                    Profile Pic
                </div>
            </div>
            <div className={row}>
                <div
                    className={`${col12} tab-menu`}
                    onClick={(e) => {
                        this.switchSelection(e);
                        this.switchProfile(<ContactInfoUpdater/>);
                        Dispatch({type: SUBTITLE, payload: 'Manage contact information'});
                    }}
                >
                    Contact info
                </div>
            </div>
            <div className={row}>
                <div
                    className={`${col12} tab-menu`}
                    onClick={(e) => {
                        this.switchSelection(e);
                        this.switchProfile(<PasswordUpdater/>);
                        Dispatch({type: SUBTITLE, payload: 'change my password'});
                    }}
                >
                    Passwords
                </div>
            </div>
            {/*////////////////////*/}
            {
                // replace role 4 for operators
                this.state.role === 4 ? // currently it belongs to the licensor
                    <div className={row}>
                        <div
                            className={`${col12} tab-menu`}
                            onClick={(e) => {
                                this.switchSelection(e);
                                this.switchProfile(
                                    <ViewOperatorProfile
                                    />
                                );
                                Dispatch({
                                    type: SUBTITLE,
                                    payload: 'manage my operator-registration\'s data'
                                });
                            }}
                        >
                            Operator profile
                        </div>
                    </div>
                    : null
            }
            {/*//////////////////////////*/}
            {/*do logout space out*/}
            <div className={`${row} space-row`}/>
            <div className={`${row} space-row`}/>
            <div className={row}>
                <div
                    className={`${col12} tab-menu logout`}
                    onClick={(e) => {
                        this.switchSelection(e);
                        this.switchProfile(<Logout/>);
                        //contextTitleManager switches are not required at this time
                    }}
                >
                    Logout
                </div>
            </div>
        </div>
    }

    /**
     *
     * Show the profile window
     *
     */
    render = () => {
        // position fixed is the cause for this failure
        return <div className={`full-screen-layout`}>

            <div className={row}>
                <div className={col2}>
                    {this.state.sideBar}
                </div>
                <div className={`${col10}`}>
                    <div className={`${row} top-bar`}>
                        {/*// put the profile pics here*/}
                        <div
                            className={`title ${col8}`}>
                            {this.props.title && this.props.title}
                            {this.props.sub_title && this.props.sub_title}
                        </div>
                        {
                            this.state.dataItem2.length > 0 &&
                            <div className={`account-owner ${col2}`}>
                                {this.state.dataItem2[0].first_name} {this.state.dataItem2[0].last_name}
                            </div>
                        }
                        {/*<div*/}
                        {/*    className={`some-button ${col1}`} style={{*/}
                        {/*    cursor: 'pointer',*/}
                        {/*    width: 60*/}
                        {/*}}>*/}
                        {/*    <FontAwesomeIcon icon={faAnchorLock}*/}
                        {/*                     style={{padding: 8, width: 40, height: 40, color: '#FFFFFF'}}*/}
                        {/*                     onClick={() => {*/}
                        {/*    setDrawerLayout(<DrawerLayout withCloseButton*/}
                        {/*                                  direction={'from-right'}/>, 1, false, 'something')*/}
                        {/*}} />*/}
                        {/*</div>*/}
                        <div className={col2}>
                            <div className={row}>
                                <div className={col4}/>
                                <div className={col2}>
                                    <Notification
                                        notifications={this.state.dataItem1}
                                        queryNotifications={this.queryNotifications}
                                        onClick={
                                            () => {
                                                Dispatch({type: SUBTITLE, payload: 'Listing all unread notifications'});
                                                Dispatch({type: PREV_ROUTE, payload: 'notifications-list'})
                                                // this.navigateTo('notifications-list', false);
                                                // this.queryNotifications();
                                                //
                                                // Show the notification in the workspace
                                                //
                                            }
                                        }/>
                                </div>
                                &ensp;
                                &ensp;
                                <div className={col2}>
                                    <Profile menuItems={
                                        [
                                            // {
                                            //     'Profile Pic': () => {
                                            //         Dispatch({type: SUBTITLE, payload: 'Edit avatar'});
                                            //         Dispatch({type: PREV_ROUTE, payload: 'avatar'});
                                            //         this.navigateTo('avatar');
                                            //     }
                                            // },
                                            // {
                                            //     'Manage contact information': () => {
                                            //         Dispatch({type: SUBTITLE, payload: 'manage contact information'});
                                            //         Dispatch({type: PREV_ROUTE, payload: 'update-contact-info'});
                                            //         this.navigateTo('update-contact-info');
                                            //     }
                                            // },
                                            {
                                                'Change my password': () => {
                                                    Dispatch({type: SUBTITLE, payload: 'Change my password'});
                                                    Dispatch({type: PREV_ROUTE, payload: 'update-password'});
                                                    this.navigateTo('update-password');
                                                }
                                            },

                                            {
                                                'Logout': () => {
                                                    // Dispatch({type: SUBTITLE, payload: 'logout'});
                                                    this.navigateTo('logout');
                                                }
                                            }]
                                    }/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={row}>
                        <div className={`${col12} dashboard-work-area`}>
                            <Outlet/>
                            {/*do a row where the dash contents are here*/}
                            <Routes>
                                {/*universal routes for profile*/}
                                <Route element={<Avatar/>} path={'avatar'}/>
                                <Route element={<Logout/>} path={'logout'}/>
                                <Route element={<PasswordUpdater/>} path={'update-password'}/>
                                <Route element={<ContactInfoUpdater/>} path={'update-contact-info'}/>
                                <Route element={<ViewOperatorProfile/>} path={'view-operator-profile'}/>
                                {/*representative main routes*/}
                                <Route element={<LicenseApplication/>} path={'rep-license-application/*'}/>
                                <Route element={<PermitApplication/>} path={'rep-permit-application/*'}/>
                                <Route element={<OperatorRegistration bufferStateData={this.bufferStateData}
                                                                      bufferredData={this.state.dataItem3}/>}
                                       path={'declare-operator/*'}/>
                                <Route element={<OperatorShareHoldersSection bufferStateData={this.bufferStateData}
                                                                             separateDeclaration/>}
                                       path={'declare-shareholder'}/>
                                <Route element={<OutletApplication/>} path={'rep-shops-application/*'}/>
                                <Route element={<UpdateShareholdersList/>} path={'update-shareholder-list'}/>
                                <Route element={<ShareholdersList separateView/>} path={'shareholders-list/*'}/>
                                <Route element={<ShareholderView/>} path={'shareholder-view/*'}/>
                                <Route element={<FileReturns/>}
                                       path={'upload-returns'}/>
                                <Route element={<ListDocuments/>}
                                       path={'list-uploaded-documents'}/>
                                {/*ceo views and tools*/}
                                {/*load the license list viewer (basically a table)*/}
                                <Route element={<LicensePermitsList/>}
                                       path={'license-permits-list/*'}/>
                                <Route element={<LicenseRenewalsList status={'renewal'}/>}
                                       path={'list-renewal-applications/*'}/>
                                {/*load the license list viewer (basically a table)*/}
                                <Route element={<ListDocuments/>}
                                       path={'list-documents/*'}/>
                                {/*load the license list viewer (basically a table)*/}
                                <Route element={<DocumentDetailsView/>}
                                       path={'view-uploaded-document'}/>
                                {/*load the license list viewer (basically a table)*/}
                                <Route element={<DefaultDashboardList/>}
                                       path={'operators-and-licenses/*'}/>
                                {/*load the license list viewer (basically a table)*/}
                                <Route element={<LicensePermitView/>}
                                       path={'license-view/*'}/>
                                <Route element={<LicenseRenewal/>}
                                       path={'renew-license/*'}/>
                                {/*load the license list viewer (basically a table)*/}
                                <Route element={<OperatorsList operatorList={readFromLocalStorage('operator', BCLB)}/>}
                                       path={'list-operators'}/>
                                <Route element={<OperatorView/>}
                                       path={'operator-view/*'}/>
                                <Route element={<DeclareBOF/>}
                                       path={'declare-bof'}/>
                                <Route element={<ListBOFMembers/>}
                                       path={'list-bof-members/*'}/>
                                <Route element={<DeclarePaymentGateway/>}
                                       path={'declare-gateway'}/>
                                <Route element={<ListGateways/>}
                                       path={'list-gateways/*'}/>
                                <Route element={<GatewayView/>}
                                       path={'view-gateway'}/>

                                {/*load the representatives list viewer (basically a table)*/}
                                <Route element={<RepresentativesList/>}
                                       path={'list-representatives'}/>
                                <Route element={<ListFileReturns/>}
                                       path={'list-return-uploads'}/>
                                {/*load the representatives list viewer (basically a table)*/}
                                <Route element={<CreateNewIssue setMandatoryFields={this.setMandatoryFields}/>}
                                       path={'raise-issue'}/>
                                {/*load the representatives list viewer (basically a table)*/}
                                <Route element={<ListIssues/>}
                                       path={'list-issues'}/>
                                <Route element={<IssueView/>}
                                       path={'view-issue'}/>

                                <Route element={<OddsProviderSection separateDeclaration
                                                                     applicable={this.state.variable}
                                                                     bufferStateData={this.props.bufferStateData}
                                                                     setMandatoryFields={this.setMandatoryFields}/>}
                                       path={'declare-odds-providers'}/>
                                <Route element={<OddsProvidersList/>}
                                       path={'list-odds-providers'}/>
                                <Route element={<OperatorEmployeeSection separateDeclaration
                                                                         applicable={this.state.variable}
                                                                         setMandatoryFields={this.setMandatoryFields}/>}
                                       path={'declare-employees'}/>
                                <Route element={<EmployeeList/>} path={'list-employees'}/>
                                <Route element={<DeclarePremise/>}
                                       path={'declare-office-premise/*'}/> {/*declare premise*/}
                                {/*<Route element={<DeclareShops/>} path={'declare-shops'}/> /!*declare premise*!/*/}
                                {/*<Route element={<DeclarePremise type={'business'}/>} path={'declare-business-premise/*'}/> /!*declare premise*!/*/}
                                <Route element={<PremiseList/>} path={'list-premises'}/> {/*premise list*/}
                                {/*<Route element={<PaginatedPremises/>} path={'list-premises'}/> /!*premise list*!/*/}
                                <Route element={<PremiseView/>} path={'premise-view/*'}/> {/*premise list*/}
                                <Route element={<EmployeeView/>} path={'employee-view/*'}/> {/*premise list*/}
                                {/*<Route element={<ListOfPremises/>} path={'list-premises'}/> /!*premise list*!/*/}
                                {/*this will host licensing views from within this link*/}
                                {/*<Route element={<TabulatedLicenseCategories/>}*/}
                                {/*       path={'categorized-licenses-permits-view/*'}/>*/}
                                <Route element={<PDFDocument/>} path={'pdf-view'}/>

                                <Route element={<ClaimedPaymentView/>} path={'record-payment'}/>
                                <Route element={<ListOfPayments/>} path={'list-payments'}/>
                                <Route element={<ClaimedPaymentView/>} path={'payment-view'}/>
                                <Route element={<MessagingComponent messages={[]} endPoint={''}/>} path={'chat'}/>
                                {/*ict routes*/}

                                {/*other routes*/}
                                <Route element={<NotificationsList/>} path={'notifications-list'}/>
                                <Route element={<NotificationView/>} path={'notification-view/*'}/>
                                <Route element={<FaQInternal/>} path={'faq'}/>
                                <Route element={<ContactInfo/>} path={'contacts'}/>
                                <Route element={<Glossary/>} path={'glossary'}/>
                                <Route element={<UserManual/>} path={'user-manual'}/>
                                {/*<Route path={'profile'} element={<ProfileViewer phonenumber={"0731346417"}*/}
                                {/*                                                emailaddress={"md@you.org"}*/}
                                {/*                                                postaddr={"my post address"}*/}
                                {/*                                                onLogout={() => {*/}
                                {/*                                                    window.clearInterval(this.state.visible)*/}
                                {/*                                                }*/}
                                {/*                                                }/>}*/}
                                {/*/>*/}
                                {/*///////////////////////////////////////////////////////////////////////////////////////*/}
                                {/*////////////////////miscellaneous routes (may be repeated elsewhere////////////////////*/}
                                {/*///////////////////////////////////////////////////////////////////////////////////////*/}
                                <Route path={'upload-supporting-documentation'}
                                       element={<ApplicationDocumentsUploadFields bufferData={k => {
                                           this.setState({passableData: null}, () => {
                                               this.setState({passableData: k});
                                           });
                                       }}/>}/>
                                <Route path={'correspondences-list'} element={<CorrespondencesList/>}/>

                                <Route path={'compose-letter'} element={<LetterComposer withSignature
                                                                                        letterHead={<BCLBLetterHead/>}
                                                                                        callback={() => this.sendRequest('put', `company/operator/${this.operator.id}`,
                                                                                            response => {
                                                                                                //console.log(response)
                                                                                                // }, {"status": 3})
                                                                                            }, {"status": 3})}/>}/>
                                <Route path={'correspondence-view/*'} element={<CorrespondenceView/>}/>

                            </Routes>
                            {this.state.currentRoute}
                        </div>
                    </div>
                </div>
            </div>

            {/*<div className={'space-row'}/>*/}
            {/*<div className={`${row} bottom-bar ${this.state.centered_styling}`}>*/}
            {/*</div>*/}
        </div>
    }
}

/**
 *
 * method is called when redux local-storage changes or updates.
 *
 * @param localStorageState the state from local-storage (having been parsed into a JSON object).
 * In this scenario, only countries are to be loaded
 *
 * See documentation of this section in the Licensor js component.
 *
 */
const
    mapStateToProps = (localStorageState) => {
        let p = {};
        const itemsOfInterest = ['url', 'operator', 'sub_title', 'title',
            'LOCAL_BACKUP', 'country', 'county', 'banks', 'email', 'role', 'title'];
        for (const item of itemsOfInterest) {
            p[item] = localStorageState[item];
        }
        return p;
    }
export default connect(mapStateToProps)(DashBoard);
