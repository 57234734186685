/**
 *
 * Class defines a means for listing licenses that have ever been applied for and their statuses.
 * Optional-Privileged. Cannot be edited strictly by BCLB but can be invoked by the operator-registration, and
 * data appended to it by either the board or the operator-registration depending on circumstances.
 *
 */
import GMS from "../../../parent-component/GMS";
import {Section, SectionColumn, SectionRow, SectionTitle} from "../../view-utils/SectionUtils";
import SimpleLabel from "../../../general-components/labels/SimpleLabel";
import {arrayOfJSONsToJSON, extractValueFromJSON, notify, readFromLocalStorage} from "../../../../MiscUtils";
import React from "react";
import {BCLB, PAYFOR} from "../../../general-components/redux/allowed-actions";
import SubmitButton from "../../../general-components/controls/buttons/SubmitButton";
import EcitizenPaymentInitiator from "../../../general-features/eCitizen-integrator/EcitizenPaymentInitiator";
import {Dispatch} from "../../../general-components/redux/app-storage";


export default class LicenseInformation extends GMS {
    constructor(props) {
        super(props);
        // read MISC_ID
        this.miscId = readFromLocalStorage('misc_id', BCLB);
        this.county = readFromLocalStorage('county', BCLB);
        this.game = readFromLocalStorage('game', BCLB);
        this.licenseId = this.miscId.constructor.name === {}.constructor.name ? this.miscId.id : this.miscId;
        this.nonRenewableLicensesList = readFromLocalStorage('DO_NOT_RENEW', BCLB);
    }

    UNSAFE_componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any) {
        // if(nextProps.payments && nextProps.payments.length > 0){
        //     this.setState({dataItem1: [...nextProps.payments]})
        // }
        if (nextProps.userData)
            this.setState({dataItem2: nextProps.userData})
    }

    /**
     *
     *
     * Acquire license data about this company
     */
    componentDidMount = () => {
        notify('Loading applications information...', 1, true);
        this.setState({dataItem2: [this.props.userData]});
    }
    listJurisdictions = (jurisdictionsList = this.miscId['jurisdiction_array']) => {
        let jurisdictions = [];
        let x = 0;
        do {
            jurisdictions.push(<SimpleLabel
                text={this.miscId ? extractValueFromJSON(jurisdictionsList[x], arrayOfJSONsToJSON(this.county)) : '-not-stated-'}
                colour/>)
            x += 1;
        } while (x < jurisdictionsList.length)
        return jurisdictions;

    }
    /**
     *
     * Help disable button
     *
     */
    disableButton = () => {
        this.setState({variable: true});
    }

    render = () => {
        // console.log(this.game, this.miscId['game_type'], arrayOfJSONsToJSON(this.game), this.miscId['game_type'],arrayOfJSONsToJSON(this.game)[this.miscId['game_type']])
        Dispatch({
            type: PAYFOR, payload: {
                // name: arrayOfJSONsToJSON(this.game)[this.miscId['game_type']], context: 'LA'
                name: this.miscId['game_type'], context: 'LA'
            }
        })
        //console.log(this.state.dataItem1, this.miscId)
        // spread out the data on the license from random data.
        return this.state.dataItem2.length > 0 && <Section>
            <SectionTitle>
                {this.state.htmlIframeElement ? this.miscId && <>
                    Pay for {this.miscId['application_type']}
                </> :
                    this.miscId && <>
                        {this.miscId['application_type']} information
                    </>}
            </SectionTitle>
            {this.state.currentRoute}
            {/*This is a very ugly solution to the problem of navigateTo method not working as expected here.*/}
            {
                this.state.visible ?
                    <SectionRow>
                        <SectionColumn>
                            <SectionRow>
                                <SectionColumn/>
                                <SectionColumn/>
                                <SectionColumn>
                                    <SubmitButton
                                        style={{background: '#469821'}}
                                        commandText={`Back to ${this.miscId['application_type']}`}
                                        callback={() => {
                                            this.setState({visible: !this.state.visible});
                                        }}/>
                                </SectionColumn>
                            </SectionRow>
                            <SectionRow>
                                <SectionColumn>
                                    <EcitizenPaymentInitiator
                                        disableButton={this.disableButton}
                                        service={readFromLocalStorage('game', BCLB)[readFromLocalStorage('LOCAL_BACKUP', BCLB).game_type]}
                                        userIdentity={{
                                            clientMSISDN: this.state.dataItem2 && this.state.dataItem2[0].phone_number,
                                            clientEmail: this.state.dataItem2 && this.state.dataItem2[0].email,
                                            clientName: this.state.dataItem2 && `${this.state.dataItem2[0]['first_name']} ${this.state.dataItem2[0]['last_name']} ${this.state.dataItem2[0]['surname']}`,
                                            clientIDNumber: this.state.dataItem2 && this.state.dataItem2[0]['user_identification_id']
                                        }}/>
                                </SectionColumn>
                            </SectionRow>
                        </SectionColumn>
                    </SectionRow> :
                    this.miscId !== null &&
                    this.state.htmlIframeElement ? this.state.htmlIframeElement : <>
                        <SectionRow>
                            <SectionColumn>

                            </SectionColumn>
                            <SectionColumn/>
                            {/*{*/}
                            {/*    // check that there is a record, for this license, and that it does not have any payment attached to it...*/}
                            {/*    // for now, disable this and allow repayment of the same in order to test the payment*/}
                            {/*    // procedure. Remove the dataItem1 check*/}
                            {/*    // this.state.dataItem1.length === 0 &&*/}
                            {
                                this.miscId.status !== 'expired' && <SectionColumn>
                                    {
                                        this.state.variable === true ? null :
                                            <SubmitButton
                                                commandText={`Initiate ${this.miscId['application_type']} Payment`}
                                                callback={() => {
                                                    this.setState({visible: !this.state.visible});
                                                }}/>
                                    }
                                </SectionColumn>
                            }
                            {
                                (this.miscId.application_type === 'License' && this.miscId.status === 'expired' &&
                                    this.nonRenewableLicensesList.includes(this.miscId.id)) ?

                                    <SectionColumn>
                                        <span style={{
                                            width: 'fit-content',
                                            border: '1px solid #c24303',
                                            borderRadius: 4,
                                            padding: 4,
                                            fontWeight: 600,
                                            color: '#c24303'
                                        }}>Application for license renewal has already been effected!
                                        </span>
                                    </SectionColumn>
                                    :
                                    this.miscId.application_type === 'License' && this.miscId.status === 'expired' &&
                                    <SectionColumn>
                                        <SubmitButton
                                            commandText={`Renew`}
                                            callback={() => {
                                                // navigate to license renewal component
                                                // Dispatch({type:MISC_ID, payload: this.miscId}) // it is already loaded in misc_id
                                                this.navigateTo(`../renew-license/${this.miscId.issuance_number}`)
                                                // this.setState({visible: !this.state.visible});
                                            }}/>
                                    </SectionColumn>

                            }
                        </SectionRow>
                        <SectionRow style={{background: '#e9eeff'}}>
                            <SectionColumn>
                                <SimpleLabel text={'operator'} fontSize={14} bold capitalize/>
                                <SimpleLabel
                                    text={this.miscId ? this.miscId['operator'] : '------'}
                                    fontSize={14} colour={'#'}/>
                            </SectionColumn>
                            <SectionColumn>
                                <SimpleLabel text={'document type'} fontSize={14} bold capitalize/>
                                <SimpleLabel
                                    text={this.miscId ? this.miscId['application_type'] : '------'}
                                    fontSize={14} colour={'#'}/>
                            </SectionColumn>
                            {
                                this.miscId && <SectionColumn>
                                    <SimpleLabel text={`Target ${this.miscId && this.miscId['jurisdiction_array'] ?
                                        this.miscId['jurisdiction_array'].length > 0 ? 'counties' : 'county' : 'counties'}  `}
                                                 fontSize={14} bold capitalize/>
                                    <SimpleLabel
                                        text={this.miscId && this.miscId['jurisdiction_array'] ? this.miscId['jurisdiction_array'][0] === 'All' ?
                                            <i>The Republic of Kenya</i> : this.listJurisdictions() : '-not-specified-'}
                                        fontSize={14} colour={'#'}/>
                                </SectionColumn>
                            }
                            <SectionColumn>
                                <SimpleLabel text={'Status'} fontSize={14} bold capitalize/>
                                <SimpleLabel text={this.miscId && this.miscId['status']}
                                             fontSize={14}
                                             colour={'#'}/>
                                {/*<SimpleLabel text={'<status-progress-bar>'} fontSize={14} colour={'#'}/>*/}
                            </SectionColumn>

                        </SectionRow>
                        <SectionRow>
                            <SectionColumn>
                                <SimpleLabel text={'Game type/Operation'} fontSize={14} bold capitalize/>
                                <SimpleLabel
                                    text={this.miscId ? this.miscId['game_type'] : '------'}
                                    fontSize={14} colour={'#'}/>
                            </SectionColumn>
                            <SectionColumn>
                                <SimpleLabel text={'Date of Application'} fontSize={14} bold capitalize/>
                                <SimpleLabel
                                    text={this.miscId ? this.miscId['expiry_date'] : '------'}
                                    fontSize={14} colour={'#'}/>
                            </SectionColumn>
                            {/*<SectionColumn>*/}
                            {/*    <SimpleLabel text={'Reports on This License'} fontSize={14} bold capitalize/>*/}
                            {/*    <SimpleLabel text={'<report-link>'} fontSize={14} colour={'#'}/>*/}
                            {/*</SectionColumn>*/}
                            <SectionColumn>
                                <SimpleLabel text={'T&Cs Applicable'} fontSize={14} bold capitalize/>
                                <SimpleLabel text={'<t&c link>'} fontSize={14} colour={'#'}/>
                            </SectionColumn>
                            <SectionColumn/>
                        </SectionRow>
                        <SectionRow>
                            <SectionColumn>
                                <SimpleLabel text={'Issued on'} fontSize={14} bold capitalize/>
                                <SimpleLabel
                                    text={this.miscId && this.miscId['issuance_date']}
                                    fontSize={14}
                                    colour={'#'}/>
                            </SectionColumn>
                            <SectionColumn>
                                <SimpleLabel text={'Expiry'} fontSize={14} bold capitalize/>
                                <SimpleLabel text={'TBD'} fontSize={14} capitalize/>
                            </SectionColumn>
                            <SectionColumn/>
                            <SectionColumn/>
                        </SectionRow>
                        <SectionRow>
                            <SectionColumn>
                                concept
                            </SectionColumn>
                        </SectionRow>
                    </>
            }
        </Section>

    }
}