import GMS from "../../parent-component/GMS";
import {Section, SectionColumn, SectionRow} from "../../rotm-custom-views/view-utils/SectionUtils";
import SubmitButton from "../../general-components/controls/buttons/SubmitButton";
import {Dispatch} from "../../general-components/redux/app-storage";
import {BCLB, LOCAL_BACKUP, SUBTITLE} from "../../general-components/redux/allowed-actions";
import {prepareIframePayload} from "./ecitizen-integrators";
import {arrayOfJSONsToJSON, confirmAction, notify, readFromLocalStorage} from "../../../MiscUtils";
import React from "react";
import {servicesMap} from "./ServicesMap";
import RadioField from "../../general-components/input-field/RadioField";
import SimpleLabel from "../../general-components/labels/SimpleLabel";

/**
 *
 *
 * Allows integration into ecitizen's pesaflow payment platform by rendering an iframe
 * with the remote payment UI here. This is mainly used when applying for licenses/permits.
 * Properties expected:
 *
 *      apiEp - The endpoint to request the iFrame from
 *      userIdentity - Rhe identifying information of the user. This includes a json object consisting of
 *                      the information listed below:
 *                                  apiClientID
 *                                  amountExpected
 *                                  serviceId
 *                                  clientIDNumber // passed as part of this props
 *                                  currency
 *                                  BillRefNumber
 *                                  billDesc
 *                                  clientName // passed as part of this props
 *                     The keys are labelled accordingly and to exactness. They are also listed in the order
 *                     that they are required.
 *
 *
 * Note: these are deprecated API URls
 *   // const eP = "https://pesaflow.ecitizen.go.ke/PaymentAPI/iframev2.1.php";
 // const eP = "https://payments.ecitizen.go.ke/PaymentAPI/iframev2.1.php";
 // const eP = "https://test.pesaflow.com/PaymentAPI/iframev2.1.php";
 */
export default class EcitizenPaymentInitiator extends GMS {
    constructor(props) {
        super(props);
        this.miscId = readFromLocalStorage('misc_id', BCLB); // the license information
        this.eP = props.apiEp ? props.apiEp : "https://payments.ecitizen.go.ke/PaymentAPI/iframev2.1.php";
        this.requiredData = {isAbsent: false, message: '', missingFields: []}
        this.ePValue = this.miscId.constructor.name === {}.constructor.name ? this.miscId.id : this.miscId;
        this.nextPayment = this.miscId.constructor.name === {}.constructor.name ?
            this.miscId['billDesc'] !== undefined ? this.miscId['billDesc'] : this.miscId['next_payment'] : 'unknown';
    }

    UNSAFE_componentWillReceiveProps = (nextProps, nextContext) => {
        if (nextProps.userIdentity)
            this.setState({
                dataItem4: readFromLocalStorage('misc_id', BCLB),
                randomData: {...nextProps.userIdentity}
            })
    }
    getServicesData = (serviceDescriptor, description) => {
        this.sendRequest('get', "finance/payment-keys", k => {
            let keys = [{apiClientID: k.data[0].api_client_id},
                {secret: k.data[0].secret},
                {key: k.data[0].key},
            ];
            this.sendRequest('get',
                `finance/service-ids/${serviceDescriptor}`,
                serviceIds => {
                    // console.log(serviceIds)
                    // if this is a due-diligence custom payment retrieval or a percentage, then retrieve the custom payment
                    const anyOfTheDesiredServicesIsTrue = serviceIds.data[0]['is_due_diligence'] === "true" ||
                        serviceIds.data[0]['is_percentage'] === "true" ||
                        serviceIds.data[0]['is_due_diligence'] === "true" ||
                        serviceIds.data[0]['is_due_diligence_shareholder'] === "true" ||
                        serviceIds.data[0]['is_additional_budget'] === "true" ||
                        serviceIds.data[0]['is_license_new'] === "true" ||
                        serviceIds.data[0]['is_license_renewal'] === "true" ||
                        serviceIds.data[0]['is_permit_new'] === "true";
                    // premise identifier
                    const isPremiseService =
                        serviceIds.data[0]['is_relocation_of_premise'] === "true" ||
                        serviceIds.data[0]['is_shop_renewal'] === "true" ||
                        serviceIds.data[0]['is_shop_new'] === "true";
                    if (anyOfTheDesiredServicesIsTrue || isPremiseService) {
                        // console.log('dataItem 4 ', this.state.dataItem4)
                        // const a = serviceDescriptor.split('&')[0].split('=')[1].replace('_', ' ');
                        const context = isPremiseService ? 'PREM' : anyOfTheDesiredServicesIsTrue ? 'LA' : 'OR';
                        // const context = isPremiseService ? 'Premise' : anyOfTheDesiredServicesIsTrue ? 'LA' : 'OR';
                        this.sendRequest('get', `finance/custom-payment/?context=${context}&context_id=${this.state.dataItem4.length === 0 ? this.miscId.id : this.state.dataItem4['id']}`,
                            value => {
                                // console.log('custom value ', value)
                                // update the service array object
                                if (value.data.length > 0) {
                                    if (serviceIds.data.length > 0) {
                                        //console.log('service ids ', serviceIds);
                                        let service = [{serviceID: serviceIds.data[0].service_id},
                                            {currency: serviceIds.data[0].currency},
                                            // {amountExpected: value.data[0]['fee']}, // change to the actual figure. Pick the latest value set
                                            {amountExpected: value.data[value.data.length - 1]['fee']}, // change to the actual figure. Pick the latest value se
                                            {billDesc: description}]; //serviceIds.data.bill_description
                                        this.setState({
                                            dataItem1: [...keys],
                                            dataItem2: [...service]
                                        }, () => this.showPaymentButton(keys));
                                    }
                                } else
                                    notify('The amount to pay is yet to be determined and set by the board. ' +
                                        'Lias with us on this matter.',
                                        2,
                                        false,
                                        'Amount is not set');

                            }, {});
                    }
                    // else(serviceIds.data.length > 0 && serviceIds.data[0]['is_percentage'] === 'false') { // && serviceIds.data[0].percentage
                    else {
                        if (serviceIds.data.length > 0) {// && serviceIds.data[0].percentage
                            let service = [{serviceID: serviceIds.data[0].service_id},
                                {currency: serviceIds.data[0].currency},
                                {amountExpected: serviceIds.data[0].amount}, // change to the actual figure.
                                {billDesc: serviceIds.data[0].bill_description}]; //serviceIds.data.bill_description
                            this.setState({
                                dataItem1: [...keys],
                                dataItem2: [...service],
                                dataItem6: serviceIds.data[0]['is_percentage']
                            }, () => this.showPaymentButton(keys));
                        } else notify(<>
                                The board cannot find the necessary service identification information
                                necessary to allow for the payment of this service!
                                <br/>
                                <br/>
                                Lias with us concerning this issue.
                            </>, 2, false,
                            'No service payment information found')
                    }
                }, {}
            )
        }, {})
    }
    /**
     *
     * Allow population of payable services options for a given service
     * @param servName the service name to pay for
     *
     * @param context
     */
    populatePayableServicesOptions = (servName, context) => {
        const serviceOption = servicesMap[servName];
        const serviceOptionKeys = Object.getOwnPropertyNames(serviceOption);
        if (serviceOption && serviceOption.constructor.name === {}.constructor.name) {
            // show multiple radio buttons
            // const options = Object.getOwnPropertyNames(serviceOption)
            // check whether there are potential payments already set in system.
            let optionsFields = [];
            let pPayment = 0;
            this.sendRequest("get",
                `finance/potential-payment/?context=${context}&context_id=${this.ePValue}`,
                response => {
                    // if potential payments are not loaded, should we ignore population of options?
                    if (response.type === 'Success') {
                        /*
                        *
                        * Treat all payments as if they have already been paid for.
                        * Loop through the potential payments and compare the context_id (record id) and billDescriptions
                        * to the current record id and next_payment values. If they have been paid for (or have their
                        * payments already initiated) show a label indicating the same
                        * else mark the service as not paid for.
                        *
                        * Lastly:
                        * If the service is not paid for, loop through the options from the ServicesMap list
                        * and show a radio button for the one that matches the next_payment field.
                        *
                         */
                        //
                        // this quick comment is aimed at allowing people to make payments on the system.
                        //
                        let serviceIsNotPaidFor = true;
                        // allow people to do payments regardless of whether potential payments exist.
                        if (response.data.length > 0) {
                            // loop through the response.data, check bill descriptions against next payment. if it exists, append a simple label
                            response.data.map((potentialPayment, index) => {
                                if (parseInt(potentialPayment.context_id) === this.ePValue && potentialPayment.billDesc === this.miscId.next_payment) {
                                    pPayment = potentialPayment;
                                    serviceIsNotPaidFor = false;
                                    optionsFields.push(<SimpleLabel
                                        text={<div
                                            style={{
                                                width: 'fit-content',
                                                border: `1px solid ${potentialPayment.status === false ? '#c24303' : '#48ab0e'}`,
                                                borderRadius: 4,
                                                padding: 4,
                                                color: potentialPayment.status === false ? '#c24303' : '#48ab0e'
                                            }}>
                                            {potentialPayment.billDesc} {potentialPayment.status === false ? ' pending confirmation' : 'paid'}
                                        </div>}
                                        fontSize={14}/>);
                                } else
                                    serviceIsNotPaidFor = true;
                            });
                        }
                        // console.log(serviceIsNotPaidFor, ' not paid for ', serviceOptionKeys)
                        if (serviceIsNotPaidFor)
                            serviceOptionKeys.map((option, idx) => {
                                const name = serviceOption[serviceOptionKeys[idx]];
                                // console.log('option ', option,' serviceOption[option]',serviceOption[option]);
                                if (option !== 'context') {
                                    // console.log(option, this.miscId.next_payment, option === this.miscId.next_payment)
                                    // console.log(this.nextPayment === option)
                                    if (this.nextPayment === option) {
                                        // console.log('pPayment bill description and next payment')
                                        optionsFields.push(<RadioField fontSize={14}
                                                                       name={'service_name'}
                                                                       changeCallback={e => {
                                                                           this.setState({dataItem5: serviceOptionKeys[idx]},
                                                                               () => this.getServicesData(name, serviceOptionKeys[idx]));
                                                                       }}
                                                                       placeholder={option}
                                        />);
                                    }
                                } else this.setState({miscField: this.props.service === 'PREM' ? this.props.service : serviceOption[option]}); // load the context into miscField
                            });
                        // console.log(optionsFields)

                        this.setState({dataItem3: [...optionsFields]});
                    }
                }, {});
        } else
            this.setState({dataItem3: servName},
                () => {
                    this.getServicesData(servicesMap[this.state.dataItem3], servicesMap[this.state.dataItem3]);
                }) // a service with no subservice options

        // show message if data is absent
        if (!this.requiredData.isAbsent)
            this.setState({randomData: this.props.userIdentity});
    }
    /**
     *
     * Proceed payment initiation.
     *
     * @param hashableData
     *
     * @param commonData
     *
     * @param commandText
     */
    proceedPaymentInitiation = (hashableData, commonData, commandText) => {
        // allow the "initiate xyz payment" button
        this.sendRequest("post",
            // `finance/potential-payment/?context=${context}&license_id=${this.ePValue}&payment_object=${this.state.dataItem5.replace(' ', '_')}`,
            `finance/potential-payment/?context=${this.state.miscField}&context_id=${this.ePValue}`,
            outcome => {
                // look for the billRefNumber
                //console.log('outcome data', outcome.data)
                if (outcome.type !== 'Fail') {
                    this.setState({e: outcome.data}, () => {
                        let payload = [];
                        // The securehash cannot be generated without the bill reference number
                        // Therefore, in order to get one, send the payload that will be used to
                        // request for the i-frame from ecitizen. The response will contain the bill reference.
                        // After all the payload items are in place, Generate the secure hash.
                        //
                        // Therefore, the securehash is present and has been confirmed via //console.log. The has
                        // not being part of the potential-payments in the GET request is not an
                        // issue (it is by inherent design)
                        //
                        // Send the request for the iframe
                        // to eCitizen and await response
                        //
                        payload = [...prepareIframePayload(commonData, hashableData,
                            this.state.e[0].billRefNumber, this.state.dataItem1[1]['secret'],
                            this.state.dataItem1[this.state.dataItem1.length - 1]['key'])];
                        //console.log('ecitizen payload ', payload)
                        // return
                        this.props.disableButton();
                        // return;
                        this.sendRequest('iframe', this.eP, () => {
                            Dispatch({
                                type: SUBTITLE,
                                payload: `View ${this.state.miscellaneousItems['application_type']} document`
                            });
                            //
                        }, payload); // Iframe expects array instead of JSON
                    });
                } else {
                    notify(<>
                        <span>The payment order - "Pay {commandText}" - cannot be completed. The reported error is</span>:
                        <br/><br/>
                        <span
                            style={{color: '#e7131b'}}>{outcome.data}</span></>, 2, false, 'Cannot complete payment')
                }
            }, arrayOfJSONsToJSON(commonData));
    }
    /**
     *
     * Shows payment button on the interface
     * @returns {JSX.Element}
     *
     */
    showPaymentButton = (keysData) => {
        // let commandText = typeof this.state.dataItem3.constructor.name === "string" ? `
        // go through all fields in dataItem2 and see if any of them is undefined
        const paymentInformationJSON = arrayOfJSONsToJSON(this.state.dataItem2);
        const dataItem2Values = Object.values(paymentInformationJSON);
        let w = 0;
        do {
            if (dataItem2Values[w] === undefined || dataItem2Values[w] === null) {
                notify(<span>
                    Payment for this service is now stopped because the information item&nbsp;
                        "{Object.getOwnPropertyNames(this.state.dataItem2[w])[0]}" is <b>
                        <i>not specified</i></b>.
                        <br/>
                        Lias with the board concerning this issue.
                        <br/>
                        <sub>Nothing to do.</sub>
                </span>,
                    2,
                    false,
                    'Payment process stopped!');
                return 0;
            }
            w += 1;
        } while (w < this.state.dataItem2.length)
        let
            commandText = this.state.dataItem3.constructor.name !== [].constructor.name ? ` 
        ${this.state.dataItem2[1].currency}.${this.state.dataItem2[2].amountExpected} for ${this.state.dataItem3}` :
                this.state.dataItem2.length > 0 && this.state.dataItem2[0].serviceID !== undefined ?
                    `${this.state.dataItem2[1].currency}.${this.state.dataItem2[2].amountExpected} for ${this.state.dataItem2[this.state.dataItem2.length - 1].billDesc}`
                    : null;
        let paymentButton = commandText && <SubmitButton
            disableButton={this.state.counts === 1}
            commandText={`Pay ${this.state.randomData && commandText} `}
            callback={(e) => {
                if (this.state.counts === 1) {
                    notify('Cannot proceed with this action of initiating another payment. One is in progress!', 3, true);
                    return;
                } else {
                    Dispatch({
                        type: SUBTITLE,
                        payload: `Pay through pesaflow for ${Object.values(this.state.dataItem2[this.state.dataItem2.length - 1])[0]}`
                    });
                    let hashableData = [
                        keysData[0], // api client id
                        // if is_percentage, do not add anything
                        {amountExpected: 50 + parseInt(this.state.dataItem2[2].amountExpected)}, // amount expected // added the convenience fee (Ksh. 50) manually
                        this.state.dataItem2[0], // service id
                        {clientIDNumber: this.state.randomData.clientIDNumber}, // national id number
                        this.state.dataItem2[1], // currency
                        // bill reference inserted here
                        this.state.dataItem2[this.state.dataItem2.length - 1],//bill description
                        {clientName: this.state.randomData.clientName}]; // name
                    let commonData = [...hashableData,
                        {clientEmail: this.state.randomData.clientEmail}, //
                        {
                            callBackURLOnSuccess: this.props.redirectToOrigin !== undefined ?
                                this.props.redirectToOrigin :
                                `https://operator.bclb.go.ke/dashboard/license-view/${this.ePValue}`
                        },
                        {pictureURL: "https://example.com/client_image.jpg"},
                        {notificationURL: "https://operator.bclb.go.ke/notifications/payments/"},
                        {
                            clientMSISDN: this.state.randomData.clientMSISDN[0] === '0' ?
                                this.state.randomData.clientMSISDN.replace('0', '+254') : this.state.randomData.clientMSISDN
                        },
                        // {format: "JSON"},
                        {format: "iframe"},
                        {sendSTK: parseInt(this.state.dataItem2[2].amountExpected) > 250050 ? "false" : "true"} // set mpesa limit to 250k
                    ];
                    //
                    // Prepare The use of the confirmation window
                    //
                    const sendSTK = parseInt(this.state.dataItem2[2].amountExpected) < 250050;
                    // this.proceedPaymentInitiation(hashableData, commonData, commandText)
                    const messageElement = <>
                        <div style={{fontSize: 15}}
                             className={'legal-notification'}>
                            {
                                sendSTK ?
                                    <>
                                        Ensure you
                                        have <b>Ksh.{parseInt(this.state.dataItem2[2].amountExpected) + 50}</b>
                                        &nbsp;in your mobile wallet before proceeding, <b><u>Ksh.50</u></b> being the
                                        convenience fee charged.
                                    </> :
                                    <>
                                        When the payment window appears, <b>take note of the 'REF NUMBER'</b> at the
                                        top-left of that display. Use that to make the payment via RTGS or bank
                                        transfer.
                                    </>
                            }
                        </div>
                        <br/>
                        <>Are you sure you wish to proceed?</>
                    </>;
                    const callbacks = [{
                        text: 'Cancel', callback: () => {
                            notify('You have cancelled this operation', 2, true);
                            this.setState({counts: null})
                        }, priority: 2
                    }, {
                        text: 'Proceed', callback: () => {
                            this.setState({counts: 1});
                            // change dynamically between PREM, LA, OR and SHARE
                            this.proceedPaymentInitiation(hashableData, commonData, commandText, 'LA');
                        }, priority: 4
                    }];
                    confirmAction(`Confirm payment via ${sendSTK ? 'Mobile money' : 'BANK/RTGS transfer'}`,
                        messageElement,
                        1,
                        callbacks)
                }

            }}
        />
        // show a notification
        this.setState({visible: paymentButton});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        super.componentDidUpdate(prevProps, prevState, snapshot);
        if (this.state.isAbsent)
            notify(this.requiredData.message, 2, false, 'Cannot effect payment yet ');
    }

    /**
     *
     * Checks for the relevant fields required for payment
     *
     */
    checkEligibilityForPayment = () => {
        if (!this.props.userIdentity['clientIDNumber']) {
            this.requiredData.message = "The 'clientIDNumber' field is missing from the userIdentity object props"
            this.requiredData.missingFields.push('clientIDNumber');
            this.requiredData.isAbsent = true;
        }
        // not part of
        if (!this.props.userIdentity['clientMSISDN']) {
            this.requiredData.missingFields.push('billDesc');
            this.requiredData.message = "The 'billDesc' field is missing from the userIdentity object props"
            this.requiredData.isAbsent = true;
        }
        if (!this.props.userIdentity['clientName']) {
            this.requiredData.missingFields.push('clientName');
            this.requiredData.message = "The 'clientName' field is missing from the userIdentity object props"
            this.requiredData.isAbsent = true;
        }
        if (this.requiredData.missingFields.length > 0) {
            this.requiredData.message =
                <span>Multiple required fields are missing. They include {this.requiredData.missingFields.join(', ')}</span>
            this.requiredData.isAbsent = true;
            notify(this.requiredData.message, 2, false, 'Missing payment information');
        } else
            this.requiredData.isAbsent = false;
        Dispatch({type: SUBTITLE, payload: 'Make Payment through eCitizen'})
    }

    componentDidMount = () => {
        // get the suitable service name
        let servName = readFromLocalStorage('pay_for', BCLB);
        // if paying for premises, declaration or relocation or renewal, load the payment directly
        // from
        // console.log(servName)
        if (servName['name']) {
            this.populatePayableServicesOptions(servName['name'], servName['context']);
        } else {
            //console.log(Object.getOwnPropertyNames(readFromLocalStorage('misc_id', BCLB)).length > 0)
            // request for data
            let gameType; // by default it's undefined game type
            const gameList = readFromLocalStorage('game', BCLB);
            const games = arrayOfJSONsToJSON(gameList);
            const gameTypes = readFromLocalStorage('misc_id', BCLB);
            try {
                if (Object.getOwnPropertyNames(gameTypes).length > 0) {
                    gameType = gameTypes.game_type;//games[gameTypes.game_type];
                }
            } catch (miscIdIsAbsent) {
                // readd local storage
                if (Object.getOwnPropertyNames(readFromLocalStorage(LOCAL_BACKUP, BCLB)).length > 0) {
                    gameType = games[readFromLocalStorage(LOCAL_BACKUP, BCLB).game_type];
                }
            }
            this.populatePayableServicesOptions(gameType, servName['context']);
            // acquire it from the backend, and write it to redux, then run the 'populatePayableServicesOptions' method
        }
        // console.log('service name serveName ', servName)
    }
    render = () => {
        // choose the operation to pay for. Read either pay_for or misc_id value then reverse-lookup from game_type
        let operationName = readFromLocalStorage('pay_for', BCLB)['name'];
        if (!operationName) {
            // read from misc_id game_type value
            operationName = this.miscId['game_type'];//arrayOfJSONsToJSON(readFromLocalStorage('game', BCLB))[this.miscId['game_type']];
        }
        this.checkEligibilityForPayment();
        return !this.requiredData.isAbsent ? <Section>
            {
                // make sure dataItem3 is not undefined
                this.state.dataItem3 && this.state.dataItem3.constructor.name === [].constructor.name && this.state.dataItem3.length > 0 ?

                    <SectionRow>
                        <SectionColumn>
                            <SectionRow>
                                <SectionColumn>
                                    <SectionRow>
                                        <SectionColumn>
                                    <span style={{borderBottom: "3px solid #1259d3"}}>
                                            <SimpleLabel bold
                                                         text={`The following items under ${operationName} are available for payment.`}
                                                         fontSize={16}
                                            />
                                        {
                                            this.state.dataItem3
                                        }
                                </span>
                                        </SectionColumn>
                                    </SectionRow>
                                    <SectionRow>
                                        {
                                            // a quick hack to test loading of the payment button and subsequent module
                                            // !this.miscId ? <SectionColumn>
                                            this.miscId ? <SectionColumn>
                                                    {
                                                        // only show this if all data that is required, is present, and that the iframe has not loaded
                                                        !this.state.htmlIframeElement && this.requiredData.isAbsent ? this.state.htmlIframeElement :
                                                            // check whether there are options. THis is denoted by the constructor of dataitem3 being
                                                            // an array
                                                            this.state.visible
                                                    }
                                                </SectionColumn> :
                                                <span>No license/permit id was found. Cannot make a potential payment without the payment record.</span>
                                        }
                                    </SectionRow>
                                    <SectionRow>
                                        <SectionColumn>
                                            {/*receipt number shown here, also duplicated in the title (dispatch of subtitle)*/}
                                        </SectionColumn>
                                    </SectionRow>
                                </SectionColumn>
                                <SectionColumn/>
                            </SectionRow>
                            <SectionRow>
                                <SectionColumn>
                                    {/*// mahali iframe inafaa kuonyeshwa*/}
                                    {this.state.htmlIframeElement}
                                </SectionColumn>
                            </SectionRow>
                        </SectionColumn>
                    </SectionRow>
                    :
                    <SectionRow>
                        <SectionColumn>
                            <SectionRow>
                                <SectionColumn>
                                    <SectionRow>
                                        <SectionColumn>
                                            <span>
                                        <SimpleLabel text={'Paying for the operation - '} fontSize={16}/>
                                                {this.state.dataItem3}
                                    </span>
                                        </SectionColumn>
                                    </SectionRow>
                                    <SectionRow>
                                        <SectionColumn>
                                            {this.state.visible}
                                        </SectionColumn>
                                    </SectionRow>
                                </SectionColumn>
                                <SectionColumn>
                                    {/*// mahali iframe inafaa kuonyeshwa*/}
                                    {this.state.htmlIframeElement}
                                </SectionColumn>
                            </SectionRow>
                            <SectionRow>
                                {
                                    // a quick hack to test loading of the payment button and subsequent module
                                    // !this.miscId ? <SectionColumn>
                                    this.miscId ? <SectionColumn>
                                            {
                                                // only show this if all data that is required, is present, and that the iframe has not loaded
                                                !this.state.htmlIframeElement && !this.requiredData.isAbsent ? this.state.htmlIframeElement :
                                                    // check whether there are options. THis is denoted by the constructor of dataitem3 being
                                                    // an array
                                                    this.state.variable
                                            }
                                        </SectionColumn> :
                                        <SectionColumn>
                                            <span>
                                                No license/permit or record id was found. Cannot make a potential payment without the payment record.
                                            </span>
                                        </SectionColumn>
                                }
                            </SectionRow>
                        </SectionColumn>
                    </SectionRow>
            }
            <SectionRow>
                {this.props.userIdentity.billDesc && this.props.userIdentity.amountExpected &&
                <SectionColumn>


                    <span>Make payment of {this.props.userIdentity.amountExpected} through pesaflow for {this.props.userIdentity.billDesc}</span>
                    {
                        this.state.e[0] && <span> receipt number <span
                            style={{background: '#5e8df3', color: '#FFFFFF'}}>{this.state.e[0].billRefNumber}</span>
                    </span>
                    }}}
                </SectionColumn>
                }
                <SectionColumn>

                </SectionColumn>
            </SectionRow>
        </Section> : null
    }
}