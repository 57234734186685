import React from "react";
import GMS from "../../../../parent-component/GMS";
import {arrayOfJSONsToJSON, fullWindowInterface, notify, readFromLocalStorage} from "../../../../../MiscUtils";
import SubmitButton from "../../../../general-components/controls/buttons/SubmitButton";

import {BCLB, LOCAL_BACKUP, NULL_BACKUP, SUBTITLE} from "../../../../general-components/redux/allowed-actions";
import {Section, SectionColumn, SectionRow} from "../../../../rotm-custom-views/view-utils/SectionUtils";
import SimpleLabel from "../../../../general-components/labels/SimpleLabel";
import CheckBoxField from "../../../../general-components/input-field/CheckBoxField";
import DocumentPrinter from "../../../../general-components/document-viewer/DocumentPrinter";
import DocumentsList from "../../../document-printer/DocumentsListView";
import {Dispatch} from "../../../../general-components/redux/app-storage";
import BCLBLetterHead from "../../../../rotm-custom-views/operator/BCLBLetterHead";
import ShareholdersList from "../../../user-ui/representative/shareholders/ShareholdersList";
import EmployeeList from "../../../user-ui/shared-views/employees-viewer/EmployeeList";
import PremiseList from "../../../user-ui/representative/premises/PremiseList";

/**
 *
 * This component provides a means for sending data to server from the state.e object inside
 * backup
 *
 * It'll access data container provider so that the send request method can be gotten and used
 *
 * OperatorSummary
 *
 */
export default class OperatorSummary extends GMS {
    constructor(props) {
        super(props);
        this.e = readFromLocalStorage(LOCAL_BACKUP, BCLB);
        this.email = readFromLocalStorage('email', BCLB);
        this.route = readFromLocalStorage('previous_route', BCLB);
        this.county = arrayOfJSONsToJSON(readFromLocalStorage('county', BCLB));
        this.state = {
            dataItem3: {},
            hasAgreedToTC: false,
            shareholderCosts: 0, // amount paid for all shareholders
            declarationCosts: 0, // amount paid for company declaration costs
            projectedLicenseCost: 0, // in the event that the license is granted, you might pay this fee
            user: {},
            contextWindow: null, // for use in collecting information about what has been filled in by BCLB
            e: props.summaryData !== undefined ? props.summaryData : this.e, // data from backup
            // by default, this refers to documents if it's a first-time registration
            miscellaneousItems: props.bufferredData ? props.bufferredData : [] // previously used in documents uploads
        };
        // console.log('summary data ', props.summaryData)
    }

    /**
     *
     * Just a convenience call
     *
     */

    componentDidMount = () => {
        Dispatch({type: SUBTITLE, payload: 'Summary of Company Information'})
        // seek custom-payments endpoint and set it to dataItem4
        this.sendRequest('get', 'finance/custom-payment', customPayments => {
            // process the custom payments here
        }, {});
        this.sendRequest('get', `account/user/?email=${this.email}`, user => {
            if (user.type === 'Success') {
                if (user.data.length > 0) {
                    this.setState({user: {...user.data[0]}}, () => {
                        this.setState({
                            dataItem5: this.generatePrintableContent(null, false,
                                this.props.summaryData !== undefined ? this.props.summaryData : this.state.e ? this.state.e : {})
                        });
                    });
                }
            }
        }, {});

    };
    /**
     *
     * Generate printable content
     *
     * @param heightInVh
     * @param forPrint
     * @param data data to use in populating this file
     * @returns {JSX.Element}
     *
     */
    generatePrintableContent = (heightInVh, forPrint, data) => {
        let shareholdersList = [], operations = [];
        let games = readFromLocalStorage('game', BCLB);
        if (games) {
            games = arrayOfJSONsToJSON(games);
        }


        if (data === undefined) {
            notify("No information found! Nothing to display.", 2, false, "Information is absent")
            return <SectionRow>
                <SectionColumn>
                    <span>
                        No operator data found
                    </span>
                </SectionColumn>
            </SectionRow>;
        } else if (Object.getOwnPropertyNames(data).length === 0)
            notify("No information found! Nothing to display.", 2, false, "Information is absent")
            //console.log('this state e' , this.state.e)
            // if role is anything else other than
        // operator with role 4
        else {
            shareholdersList = data['operator_individual_shareholders'] && data['operator_company_shareholders'] ?
                [...data['operator_individual_shareholders'], ...data['operator_company_shareholders']] :
                data['operator_individual_shareholders'] ? [...data['operator_individual_shareholders']] :
                    data['operator_company_shareholders'] ? [...data['operator_company_shareholders']] :
                        [];
            // console.log('shareholders list ',shareholdersList)
            if (data['game_type']) {
                let g = 0;
                do {
                    operations.push(
                        <SectionRow>
                            <SectionColumn>
                                {
                                    games[data['game_type'][g]]
                                }
                            </SectionColumn>
                        </SectionRow>);
                    g += 1;
                } while (g < data['game_type'].length);
            }
        }
        // [...data['operator_shareholders']];
        const k = forPrint && heightInVh === null ? {height: heightInVh} : heightInVh === null ? {
            height: '80vh',
            overflowY: 'scroll',
            scrollbarWidth: 'thin',
        } : {height: heightInVh};
        // kama kuna declarations, create a useful UI
        // facilitate payments of due diligence
        const miscId = readFromLocalStorage('misc_id', BCLB);
        return <Section>
            {/*{*/}
            {/*    // only show these if custom payments exist that have Operator registration set*/}
            {/*    this.state.variable === true ? null :*/}
            {/*        <SectionRow>*/}
            {/*            <SectionColumn/>*/}
            {/*            <SectionColumn/>*/}
            {/*            <SectionColumn>*/}
            {/*                <br />*/}
            {/*                <SubmitButton*/}
            {/*                    commandText={`Initiate ${miscId['next_payment']} Payment`}*/}
            {/*                    callback={() => {*/}
            {/*                        this.setState({visible: !this.state.visible});*/}
            {/*                    }}/>*/}
            {/*            </SectionColumn>*/}
            {/*        </SectionRow>*/}
            {/*}*/}
            {/*{*/}
            {/*    this.state.visible &&*/}
            {/*    <SectionRow>*/}
            {/*        <SectionColumn>*/}
            {/*            <SectionRow>*/}
            {/*                <SectionColumn/>*/}
            {/*                <SectionColumn/>*/}
            {/*                <SectionColumn>*/}
            {/*                    <SubmitButton*/}
            {/*                        style={{background: '#469821'}}*/}
            {/*                        commandText={`Back to ${miscId['application_type']}`}*/}
            {/*                        callback={() => {*/}
            {/*                            this.setState({visible: !this.state.visible});*/}
            {/*                        }}/>*/}
            {/*                </SectionColumn>*/}
            {/*            </SectionRow>*/}
            {/*            <SectionRow>*/}
            {/*                <SectionColumn>*/}
            {/*                    <EcitizenPaymentInitiator*/}
            {/*                        disableButton={this.disableButton}*/}
            {/*                        service={readFromLocalStorage('game', BCLB)[readFromLocalStorage('LOCAL_BACKUP', BCLB).game_type]}*/}
            {/*                        userIdentity={{*/}
            {/*                            clientMSISDN: this.state.dataItem2 && this.state.dataItem2[0].phone_number,*/}
            {/*                            clientEmail: this.state.dataItem2 && this.state.dataItem2[0].email,*/}
            {/*                            clientName: this.state.dataItem2 && `${this.state.dataItem2[0]['first_name']} ${this.state.dataItem2[0]['last_name']} ${this.state.dataItem2[0]['surname']}`,*/}
            {/*                            clientIDNumber: this.state.dataItem2 && this.state.dataItem2[0]['user_identification_id']*/}
            {/*                        }}/>*/}
            {/*                </SectionColumn>*/}
            {/*            </SectionRow>*/}
            {/*        </SectionColumn>*/}
            {/*    </SectionRow>*/}
            {/*}*/}
            <SectionRow>
                <BCLBLetterHead/>
            </SectionRow>
            {
                Object.getOwnPropertyNames(this.state.user).length > 0 &&
                <SectionRow>
                    {/*addressees the director*/}
                    <SectionColumn>
                        <SectionRow>
                            <SectionColumn>{this.state.user.first_name} {this.state.user.last_name} {this.state.user.surname},</SectionColumn>
                        </SectionRow>
                        <SectionRow>
                            <SectionColumn>The {this.state.user.position},</SectionColumn>
                        </SectionRow>
                        <SectionRow>
                            <SectionColumn>{this.state.user.company_name},</SectionColumn>
                        </SectionRow>
                        {
                            // the postal address of the company: Not the user!
                            this.state.user.postal_address.length > 0 && <SectionRow>
                                <SectionColumn>{this.state.user.postal_address}</SectionColumn>
                            </SectionRow>
                        }
                        <SectionRow>
                            <SectionColumn>Phone {this.state.user.phone_number}</SectionColumn>
                        </SectionRow>
                    </SectionColumn>
                </SectionRow>
            }
            <SectionRow style={{borderTop: '4px solid #065bd2'}}/>
            <SectionRow>
                <SectionColumn>
                    <br/>
                    <br/>
                    <br/>
                </SectionColumn>
            </SectionRow>
            <SectionRow>
                <SectionColumn style={{
                    color: data['status'] === 'Active' ? '#057a47' : data['status'] === 'Suspended' ? '#e14912' : '#606164',
                    textTransform: 'uppercase',
                    fontSize: 24,
                    width: '20%'
                }}>
                    {data['company_name']}
                    <span style={{fontSize: 18, fontWeight: 400, fontStyle: 'oblique'}}>
                    {data['company_type'] &&
                    <span>, {data['company_type']}</span>
                    }
                </span>
                </SectionColumn>
                {
                    data['status'] !== undefined && <SectionColumn>
                            <span style={{
                                padding: 4,
                                // border: '1px solid #606164',
                                paddingRight: 0,
                                marginRight: 4
                            }}>
                                {/*&ensp;<b>Status</b>*/}
                                &nbsp;<span
                                style={{
                                    fontWeight: 700,
                                    borderRadius: 4,
                                    background: data['status'] === 'Active' ? '#057a47' : data['status'] === 'Suspended' ? '#e14912' : '#606164',
                                    color: '#FFFFFF',
                                    padding: 8
                                }}>{data['status']}</span>
                            </span>
                    </SectionColumn>
                }
            </SectionRow>
            <SectionRow boxedColour>
                <SectionColumn>
                    <SectionRow>
                        <SectionColumn>
                            <span style={{fontWeight: 600, borderBottom: '2px solid #5e8df3'}}>Company brief</span>
                            {/*<span style={{fontWeight: 500}}>About company</span>*/}
                            <SectionRow>
                                <SectionColumn>
                                    <span>
                                        This is a <b>local company</b> incorporated in the Republic of Kenya as per the
                                                <i><b>The Company's Act, No. 17 of 2015</b></i> with the business registration number <span
                                        style={{color: '#195ae7'}}>{this.state.user['company_registration_number']}</span>.<br/>
                                                The company is operating under the name&ensp;<span
                                        style={{color: '#195ae7'}}>{data['local_business_name']}.</span>
                                    </span>
                                    {/*{*/}
                                    {/*    // data['foreign_company_reg_number'] !== '' ?*/}
                                    {/*    data['foreign_company_origin'] !== 'Kenya' ?*/}
                                    {/*        data['town'] && data['county'] ? <span>*/}
                                    {/*                This company is situated in {data['town']} , {this.county[data['county']]} county*/}
                                    {/*                /!*open in map-viewer*!/*/}
                                    {/*                &nbsp;(<a*/}
                                    {/*                href={'#'}>{data['coordinates']}</a>) - in <i>{data['building_name']}</i> building {data['floor'] &&*/}
                                    {/*            <span>floor {data['floor']}</span>} {data['room'] &&*/}
                                    {/*            <span>room {data['room']}</span>}*/}
                                    {/*                {data['plot_no'] && <span>, on L/R Number {data['plot_no']}.</span>}*/}
                                    {/*            </span> :*/}
                                    {/*            `This is a registered foreign company under registration number ${data['foreign_company_reg_number']}` :*/}
                                    {/*        <span>*/}
                                    {/*    This is a <b>local company</b> incorporated in the Republic of Kenya as per the*/}
                                    {/*            <i><b>The Company's Act, No. 17 of 2015</b></i> with the business registration number <span*/}
                                    {/*            style={{color: '#195ae7'}}>{data['local_company_reg_number']}</span>.<br/>*/}
                                    {/*            The company is operating under the name&ensp;<span*/}
                                    {/*            style={{color: '#195ae7'}}>{data['local_business_name']}</span>*/}
                                    {/*             represented by <span*/}
                                    {/*            style={{color: '#195ae7'}}>{data['operator_agent']}</span>*/}
                                    {/*</span>*/}
                                    {/*}*/}
                                </SectionColumn>
                            </SectionRow>
                            <SectionRow>
                                <SectionColumn>
                            <span>
                            <SimpleLabel bold capitalize fontSize={14} text={'Tax pin'}/>
                            <SimpleLabel capitalize fontSize={14} text={data['local_pin_number']}/>
                            </span>
                                </SectionColumn>
                                <SectionColumn>
                            <span>
                            <SimpleLabel bold capitalize fontSize={14} text={'Office contact'}/>
                            <SimpleLabel capitalize fontSize={14} text={data['operator_phone_number']}/>
                            </span>
                                </SectionColumn>
                                <SectionColumn>
                                            <span>
                                                <SimpleLabel text={'Phone number'} bold fontSize={14}/>
                                            <SimpleLabel text={data['operator_phone_number']} fontSize={14}/>
                                            </span>
                                </SectionColumn>
                                <SectionColumn>
                                            <span>
                                            <SimpleLabel text={'E-mail'} bold fontSize={14}/>
                                            <SimpleLabel text={data['operator_email']} fontSize={14}/></span>
                                </SectionColumn>
                            </SectionRow>
                        </SectionColumn>
                    </SectionRow>
                    <SectionRow>
                        <SectionColumn>
                            <SectionRow>
                                <SectionColumn>
                                    <SectionRow>

                                        {/*</SectionRow>*/}
                                        {/*<SectionRow>*/}
                                        {/*<SectionColumn>*/}
                                        {/*    /!*find ways of showing websites that are returned as an array.*!/*/}
                                        {/*    /!*evaluate the field names that come back. It's either 'web_addresses' or 'local_web_address'*!/*/}
                                        {/*    <span>*/}
                                        {/*        <SimpleLabel text={'domain/website'} bold fontSize={14}/>*/}
                                        {/*        {data['web_addresses'] ?*/}
                                        {/*            <a href={typeof data['web_addresses'] == 'Array' ?*/}
                                        {/*                data['web_addresses'][0] : data['web_addresses'].indexOf(',') !== -1 ?*/}
                                        {/*                    data['web_addresses'].split(',')[0] : data['web_addresses']}>*/}
                                        {/*                <SimpleLabel text={data['web_addresses']} fontSize={14}/></a>*/}
                                        {/*            : data['local_website_address'] ?*/}
                                        {/*                data['local_website_address'].map((address, idx) => {*/}
                                        {/*                    return <a href={address}>*/}
                                        {/*                        <SimpleLabel text={address}*/}
                                        {/*                                     fontSize={14}/>*/}
                                        {/*                    </a>*/}
                                        {/*                })*/}
                                        {/*                : null*/}
                                        {/*        }*/}

                                        {/*    </span>*/}
                                        {/*</SectionColumn>*/}
                                    </SectionRow>
                                    <SectionRow>
                                        {
                                            data['operator_postal_address'] && <SectionColumn>
                            <span>
                            <SimpleLabel bold capitalize fontSize={14} text={'Post address'}/>
                            <SimpleLabel capitalize fontSize={14}
                                         text={data['operator_zip_code'] ? `${data['operator_postal_address']} (ZIP ${data['operator_zip_code']})` : data['operator_postal-address']}/>
                            </span>
                                            </SectionColumn>}
                                        <SectionColumn/>
                                        <SectionColumn/>
                                    </SectionRow>
                                </SectionColumn>
                            </SectionRow>
                        </SectionColumn>
                    </SectionRow>
                </SectionColumn>
            </SectionRow>
            <SectionRow boxedColour>
                {/*This is the company introduction*/}
                <SectionColumn>
                    {/*<span style={{fontWeight: 600, borderBottom: '2px solid #838588'}}>Introduction</span>*/}
                    <span style={{fontWeight: 600, borderBottom: '2px solid #5e8df3'}}>Intended operations</span>
                    {
                        operations.length > 0 && <SectionRow>
                            <SectionColumn>
                                {operations}
                            </SectionColumn>
                        </SectionRow>
                    }
                </SectionColumn>
            </SectionRow>
            <SectionRow boxedColour>
                {/*This is the company introduction*/}
                <SectionColumn>
                    {/*<span style={{fontWeight: 600, borderBottom: '2px solid #838588'}}>Introduction</span>*/}
                    <span style={{fontWeight: 600, borderBottom: '2px solid #5e8df3'}}>Introduction</span>
                    <SectionRow>
                        <SectionColumn>
                            {data['proposal']}
                        </SectionColumn>
                    </SectionRow>
                </SectionColumn>
            </SectionRow>
            {
                shareholdersList.length > 0 && <SectionRow boxedColour>
                    {/*This is the company's shareholders*/}
                    <SectionColumn>
                        {/*<span style={{fontWeight: 600, borderBottom: '2px solid #838588'}}>Shareholders/directors</span>*/}
                        <SectionRow style={{
                            borderBottom: '2px solid #5e8df3'
                        }}>
                            <SectionColumn>
                            <span style={{
                                // width: 180,
                                fontWeight: 600,
                                // borderBottom: '2px solid #5e8df3'
                                // }}>{shareholdersList.length > 0 && shareholdersList.length}&nbsp;Shareholders</span>
                            }}>Shareholders</span>
                            </SectionColumn>
                            <SectionColumn/>
                        </SectionRow>
                        <SectionRow>
                            <SectionColumn>
                                {this.props.summaryData ? <ShareholdersList noTitle/> :
                                    <ShareholdersList noTitle shareholdersList={shareholdersList}/>}
                                {/*<ListOfShareholders*/}
                                {/*    noTitle*/}
                                {/*    allowDownload={this.props.bufferredData && this.props.bufferredData.length > 0}*/}
                                {/*    shareholderList={this.props.bufferredData ? this.props.bufferredData.length > 0 &&*/}
                                {/*        this.props.bufferredData[0].shareholders : shareholdersList}/>*/}
                            </SectionColumn>
                        </SectionRow>
                    </SectionColumn>
                </SectionRow>
            }
            {/*{*/}
            {/*    data['banks'] && <SectionRow boxedColour>*/}
            {/*        <SectionColumn>*/}
            {/*            <ListOfBanks noTitle banks={data['banks']}/>*/}
            {/*        </SectionColumn>*/}
            {/*    </SectionRow>*/}
            {/*}*/}
            {
                this.route !== 'finalize-operator-registration' && <SectionRow boxedColour>
                    {/*This is the company's premises/outlet information (viewable by map)*/}
                    <SectionColumn>
                        {/*<span style={{fontWeight: 600, borderBottom: '2px solid #838588'}}>Offices and shops</span>*/}
                        <SectionRow style={{borderBottom: '2px solid #5e8df3'}}>
                            <SectionColumn>
                            <span style={{
                                width: 180,
                                fontWeight: 600,
                                // borderBottom: '2px solid #5e8df3'
                            }}>Offices and shops
                            </span>
                            </SectionColumn>
                            <SectionColumn/>
                        </SectionRow>
                        <SectionRow>
                            <SectionColumn>
                                <PremiseList/>
                            </SectionColumn>
                            {/*<ListOfPremises noTitle*/}
                            {/*                operatorId={data['id']}*/}
                            {/*                // outletsList={data['operator_shops'] && data['operator_shops']}*/}
                            {/*/>*/}
                        </SectionRow>
                    </SectionColumn>
                </SectionRow>}
            {/*{*/}
            {/*    data['operator_shops'] && data['operator_shops'].length > 0 &&*/}
            {/*    <SectionRow boxedColour>*/}
            {/*        /!*This is the company's premises/outlet information (viewable by map)*!/*/}
            {/*        <SectionColumn>*/}
            {/*            /!*<span style={{fontWeight: 600, borderBottom: '2px solid #838588'}}>Offices and shops</span>*!/*/}
            {/*            <SectionRow style={{borderBottom: '2px solid #5e8df3'}}>*/}
            {/*                <SectionColumn>*/}
            {/*                <span style={{*/}
            {/*                    width: 180,*/}
            {/*                    fontWeight: 600,*/}
            {/*                    // borderBottom: '2px solid #5e8df3'*/}
            {/*                }}>Offices and shops*/}
            {/*                    /!*{data['operator_shops'] && <span> - {data['operator_shops'].length}</span>}*!/*/}
            {/*                </span>*/}
            {/*                </SectionColumn>*/}
            {/*                <SectionColumn/>*/}
            {/*            </SectionRow>*/}
            {/*            <SectionRow>*/}
            {/*                {*/}
            {/*                    data['operator_shops'].length > 0 ?*/}
            {/*                        <ListOfPremises noTitle*/}
            {/*                                        outletsList={data['operator_shops'] && data['operator_shops']}/> :*/}
            {/*                        null*/}
            {/*                }*/}
            {/*            </SectionRow>*/}
            {/*        </SectionColumn>*/}
            {/*    </SectionRow>*/}
            {/*}*/}
            {
                data['operator_employees'] && data['operator_employees'].length > 0 && <SectionRow boxedColour>
                    {/*This is the company's list of employees*/}
                    <SectionColumn>
                        <SectionRow style={{borderBottom: '2px solid #5e8df3'}}>
                            <SectionColumn>
                            <span style={{
                                width: 180,
                                fontWeight: 600,
                                // borderBottom: '2px solid #5e8df3'
                            }}>
                                {
                                    data['operator_employees'] && data['operator_employees'].length
                                }&nbsp;Employees</span>
                            </SectionColumn>
                            <SectionColumn/>
                        </SectionRow>
                        <SectionRow>
                            <EmployeeList noTitle/>
                        </SectionRow>
                    </SectionColumn>
                </SectionRow>
            }
            {
                data['operator_docs'] && data['operator_docs'].length > 0 ?
                    <SectionRow boxedColour>
                        <SectionColumn>
                            <SectionRow style={{borderBottom: '2px solid #5e8df3'}}>
                                <SectionColumn>
                            <span style={{
                                width: 180,
                                fontWeight: 600,
                                // borderBottom: '2px solid #5e8df3'
                            }}>
                                Company documents
                            </span>
                                </SectionColumn>
                            </SectionRow>
                            <SectionRow>
                                <SectionColumn>
                                    <DocumentsList noTitle documentLinks={data['operator_docs']}/>
                                </SectionColumn>
                            </SectionRow>
                        </SectionColumn>
                    </SectionRow>
                    : null
            }
            {
                data['licenses'] && data['licenses'].length > 0 && <SectionRow boxedColour>
                    {/*This is the company's list of licenses, ordered by latest one first*/}
                    <SectionColumn>
                        {/*<span style={{fontWeight: 600, borderBottom: '2px solid #838588'}}>Licenses</span>*/}
                        <span style={{fontWeight: 600, borderBottom: '2px solid #5e8df3'}}>Licenses</span>
                        <SectionRow>

                        </SectionRow>
                    </SectionColumn>
                </SectionRow>
            }
            <SectionRow>
                <SectionColumn className={'legal-notification'}>
                    <b>Note:</b>
                    <span>
                            Giving false information to the Board is an offence under section 5(A) of
                            the Betting, Lotteries and Gaming Act (<i>Cap 131</i>) laws of Kenya
                        </span>
                </SectionColumn>
            </SectionRow>
        </Section>
    }
    /**
     *
     *
     *
     * @returns {JSX.Element}
     *
     */

    render = () => {
        return (
            <SectionColumn>
                {this.state.dataItem1}
                {
                    this.props.disablePrint ? null : <SectionRow style={{
                        marginTop: 4,
                        padding: 8,
                        // borderBottom: '1px solid #F4B1DB',
                        // background: '#f6d1e9',
                        // background: '#f6d1e9',
                        color: "#100a0a"
                    }}>
                        <SectionColumn>
                            <SubmitButton style={{width: 'fit-content', height: 36, padding: 8}}
                                          commandText={'Print copy'} callback={() => {
                                // print(ids)
                                const currentLocation = window.location;
                                fullWindowInterface(<DocumentPrinter
                                        // content={this.generatePrintableContent(null, true)}/>,
                                        content={this.state.dataItem5}/>,
                                    'Report on declared information', () => {
                                        window.location = currentLocation;
                                    });
                            }}/>
                        </SectionColumn>
                        {/*allow changing the height of the printable content to match the height of the prining window*/}
                    </SectionRow>
                }
                {/*{!this.state.htmlIframeElement && this.generatePrintableContent(null, false)}*/}
                {this.state.dataItem5}
                <SectionRow>
                    <SectionColumn>
                        {
                            this.state.dataItem6
                        }
                    </SectionColumn>

                    <SectionColumn/>
                </SectionRow>
                <SectionRow>
                    <SectionColumn>
                        {this.state.dataItem2}
                    </SectionColumn>
                </SectionRow>
                {this.state.currentRoute}
                {
                    this.props.summaryData ? null :
                        <SectionRow>
                            {/*<SectionColumn/>*/}
                            <SectionColumn>
                                {/*// this is the only place that the input-field makes sence to use it directly without*/}
                                {/*// added functionality of the InputField component*/}
                                {/*<SimpleLabel text= bold/>*/}
                                <CheckBoxField
                                    // bold
                                    // capitalize
                                    changeCallback={() => this.setState({hasAgreedToTC: !this.state.hasAgreedToTC})}
                                    name={''}
                                    // placeholder={'The company agrees to the terms and conditions of BCLB'}/>
                                    placeholder={'I declare that the above information provided is true to the best of my knowledge.'}/>
                            </SectionColumn>
                            <SectionColumn>
                                {this.props.allowFindingsCollection}
                                {
                                    this.props.allowFindingsCollection ?
                                        this.props.allowFindingsCollection :
                                        this.state.hasAgreedToTC ? (
                                            <SubmitButton
                                                commandText={"Complete Operator Declaration"}
                                                callback={() => {
                                                    let storage = this.state.e;
                                                    /**
                                                     * get all keys starting with ag_
                                                     */
                                                    // convert bank and bank-branch branch to integer
                                                    // storage['bank_branch'] = parseInt(storage['bank_branch']);
                                                    // storage['bank'] = parseInt(storage['bank']);

                                                    if (Object.getOwnPropertyNames(storage).length > 0) {
                                                        let bufferedData = this.state.e;
                                                        //
                                                        // remove shareholder data because they are registered separate
                                                        // from the operator.
                                                        // The process follows:
                                                        // 1. Register the operator.
                                                        // 2. Use the id of the operator that comes back, write it into misc_id
                                                        // 3. Register the shareholders using the id of the operator
                                                        // Error "Operator linked to agent doesn't exist"
                                                        delete bufferedData['operator_individual_shareholders'];
                                                        delete bufferedData['operator_company_shareholders'];
                                                        delete bufferedData['operator_shareholders'];
                                                        this.sendRequest(
                                                            "post",
                                                            'company/operator',
                                                            (response) => {
                                                                let x = 0; // a universal counter
                                                                if (!response) {
                                                                    notify('Declined. You (the representative)' +
                                                                        ' might already be representing a company or are ' +
                                                                        'not allowed to represent one at this time!',
                                                                        3, false, 'Operator declaration denied');
                                                                    this.navigateTo('../../list-operators', false);
                                                                } else {
                                                                    if (response.type === 'Fail') {
                                                                        notify(response.data ?
                                                                                response.data[0] : 'An internal server error occurred. ' +
                                                                                'Contact the system administrator for assistance',
                                                                            response.priority, false, response.message ? response.message : 'internal Server Error!')
                                                                    } else {
                                                                        // window.alert(`response message for operator declaration ${response.message}`)
                                                                        notify(response.message, 4, true);
                                                                        // record and create shareholders using the operator id that came back
                                                                        if (this.state.miscellaneousItems.constructor.name === [].constructor.name) {
                                                                            // loop through the operator_shareholders and extract
                                                                            // this will not work if the page is refreshed
                                                                            // this is shareholder data
                                                                            if (this.state.miscellaneousItems.length > 0) {
                                                                                const items = arrayOfJSONsToJSON(this.state.miscellaneousItems);
                                                                                if (Object.getOwnPropertyNames(items).includes('shareholders')) {
                                                                                    items.shareholders.map((shareholder, index) => {
                                                                                        let target_point = 'individual-shareholder';
                                                                                        let shareholderData = shareholder;
                                                                                        let fd = new FormData();
                                                                                        if (Object.getOwnPropertyNames(shareholderData).includes('sh_kra_doc')
                                                                                            || Object.getOwnPropertyNames(shareholderData).includes('sh_cogc_file')
                                                                                            || Object.getOwnPropertyNames(shareholderData).includes('sh_passport_doc')
                                                                                            || Object.getOwnPropertyNames(shareholderData).includes('shareholder_kra_tcc_file')) {
                                                                                            if (Object.getOwnPropertyNames(shareholderData).includes('sh_passport_doc'))
                                                                                                fd.append('shareholder_id_file', shareholderData['sh_passport_doc']);
                                                                                            if (Object.getOwnPropertyNames(shareholderData).includes('sh_kra_doc'))
                                                                                                fd.append('shareholder_kra_file', shareholderData['sh_kra_doc']);
                                                                                            if (Object.getOwnPropertyNames(shareholderData).includes('sh_cogc_file'))
                                                                                                fd.append('shareholder_cogc_file', shareholderData['sh_cogc_file']);
                                                                                            if (Object.getOwnPropertyNames(shareholderData).includes('shareholder_kra_tcc_file'))
                                                                                                fd.append('shareholder_kra_tcc_file', shareholderData['shareholder_kra_tcc_file']);
                                                                                            // append data to it and remove the two items
                                                                                            // remove files from the locally-buffered data
                                                                                            delete shareholderData['sh_kra_doc'];
                                                                                            delete shareholderData['sh_cogc_file'];
                                                                                            delete shareholderData['shareholder_kra_tcc_file'];
                                                                                            target_point = 'individual-shareholder';
                                                                                        } else if (Object.getOwnPropertyNames(shareholderData).includes('company_kra_doc')
                                                                                            || Object.getOwnPropertyNames(shareholderData).includes('company_notarized_sh_doc')) {
                                                                                            if (shareholderData['company_kra_doc'] !== undefined)
                                                                                                fd.append('company_kra_file', shareholderData['company_kra_doc']);
                                                                                            if (shareholderData['company_notarized_sh_doc'] !== undefined)
                                                                                                fd.append('company_notarized_sh_doc', shareholderData['company_notarized_sh_doc']);
                                                                                            // delete shareholderData['company_kra_doc'];
                                                                                            target_point = 'company-shareholder';
                                                                                        }
                                                                                        // append data to it and remove the two items
                                                                                        // remove files from the locally-buffered data
                                                                                        fd.append('data', JSON.stringify(shareholderData));
                                                                                        // upload shareholders with their documents
                                                                                        this.sendRequest(
                                                                                            "post",
                                                                                            `company/operator/${target_point}`,
                                                                                            // the identity of the operator-registration that was created
                                                                                            response => {
                                                                                                if (response.priority !== 1 && response.priority !== 4) {
                                                                                                    notify(response.message, response.priority, true);
                                                                                                } else
                                                                                                    notify(response.message, response.priority, true);
                                                                                                if (index === items.shareholders.length - 1) {
                                                                                                    let newState = {};
                                                                                                    this.setState({e: {...newState}});
                                                                                                    window.setTimeout(() => {
                                                                                                        Dispatch({type: NULL_BACKUP});
                                                                                                        window.location.reload();
                                                                                                        this.navigateTo('../list-operators');
                                                                                                    }, 2500);
                                                                                                }
                                                                                            }, fd // the formData with the files and shareholder data in there...
                                                                                        );
                                                                                    });
                                                                                }
                                                                            }

                                                                            // if (Object.getOwnPropertyNames(items).includes('biosection')) {
                                                                            //     let otherFiles = new FormData();
                                                                            //     otherFiles.append('llp', items.biosection);
                                                                            //     this.sendRequest(
                                                                            //         "post",
                                                                            //         `account/upload/?obj=operator&id=${response.data[0].id}`,
                                                                            //         // `account/upload/?obj=operator&id=61`,
                                                                            //         // the identity of the operator-registration that was created
                                                                            //         response => {
                                                                            //             if (
                                                                            //                 response.priority !== 1 &&
                                                                            //                 response.priority !== 4
                                                                            //             ) {
                                                                            //                 notify(
                                                                            //                     response.message,
                                                                            //                     response.priority, true
                                                                            //                 );
                                                                            //             } else
                                                                            //                 notify(
                                                                            //                     response.message,
                                                                            //                     response.priority,
                                                                            //                     true
                                                                            //                 );
                                                                            //         }, otherFiles // the formData with the files in there...
                                                                            //     );
                                                                            // }
                                                                            window.setTimeout(() => {
                                                                                Dispatch({type: NULL_BACKUP});
                                                                                window.location.reload();
                                                                                this.navigateTo('../list-operators');
                                                                            }, 2500);

                                                                        }

                                                                    }

                                                                    // force a window reload
                                                                    // check if the path is what is causing it not redirect properly
                                                                }


                                                            }
                                                        );
                                                    }
                                                    /**
                                                     * The code below should execute after the response from server is either
                                                     * 200 or [not-recommended-but-still-workable, 304]. follow the steps below:
                                                     *
                                                     * 1. nullify the backup if the response is positive else LEAVE IT AS IS.
                                                     * 2. allow the server to respond with operator-registration list as above and update
                                                     *      redux to allow table to reload with fresh data. Else, have the
                                                     *      table render data about the operator-registration in-memory to prevent redux
                                                     *      memory limit crossing (currently, from forums its limited to
                                                     *      5MB though can be over-riden by user manually.
                                                     *
                                                     */
                                                }}
                                            />
                                        ) : null
                                }
                            </SectionColumn>
                        </SectionRow>
                }
            </SectionColumn>
        );
    };
}
