/**
 * This is a compilation of variables/commands that enable
 * storage of data.
 */
/**
 *
 * Primary key to fetch that contains data about BCLB
 * @type {string} the constant identifier
 *
 */
export const BCLB = 'BCLB';
/**
 *
 * Facilitates communication of information via correspondences
 * @type {string}
 *
 */
export const LETTER = 'LETTER';
/**
 *
 * Array consisting of items that should not have the renew button. These
 * items are those whose renewals have been effected.
 *
 * @type {string}
 */
export const DONOTRENEW = 'DO_NOT_RENEW'
/**
 *
 * a record to be viewed.
 *
 * @type {string}
 */
export const VIEWABLE_ITEM = 'VIEWABLE_ITEM';

/**
 *
 * Used to demarcate the last page which was loaded.
 *
 * @type {string}
 */
export const SUB_PREVIOUS_PAGE = 'SUB_PREVIOUS_PAGE';
export const SUB_NEXT_PAGE = 'SUB_NEXT_PAGE';
export const CLEAR_PAGE_NUMBERS = 'CLEAR_PAGE_NUMBERS';
export const PAGE_PARAMETERS = 'PAGE_PARAMETERS';
/**
 *
 * Hardware and software device collections
 *
 * @type {string}
 */
export const HARDWAREDEVICES = 'HARDWARE_DEVICES';
export const SOFTWAREDEVICES = 'SOFTWARE_DEVICES';
/**
 *
 * Command for directing the previous route that would be registered
 * by the navigateTo(route, state, replacePathInHistory) method call of
 * the default container
 *
 * @type {string}
 */
export const PREV_ROUTE = 'PREV_ROUTE';
/**
 *
 * blur fields for is an array of tab names of fields that need to be blurred out
 * @type {string}
 *
 */
export const BLUR_FIELDS_FOR = 'BLUR_FIELDS_FOR';

export const APPLICATION_STATUS = 'APPLICATION_STATUS';
/**
 *
 * url to navigate to
 * @type {string}
 */
export const URL = 'URL';
/**
 *
 * Field for referring to the misc id that is to be used elsewhere in the UI
 * @type {string}
 *
 */
export const MISC_ID = 'MISC_ID';
/**
 *
 * String constant for identifying a title action
 * @type {string}
 *
 */
export const TITLE = 'TITLE';
/**
 *
 * Enable storage of the route index for use in routing
 *
 * @type {string}
 */
export const ROUTEINDEX = 'ROUTEINDEX';
/**
 *
 * change or do subtitles
 * @type {string}
 */
export const SUBTITLE = 'SUBTITLE';
/**
 *
 * Record fee types
 *
 */
export const FEE_TYPE = 'FEE_TYPE';
// /**
//  *
//  * Operator id
//  * @type {string}
//  *
//  */
// export const OPERATOR_ID = 'OPERATOR_ID';
/**
 *
 *
 *
 */
export const NOTIFICATIONS = 'NOTIFICATIONS';
/**
 *
 * Login command. Applies to everyone who is capable of
 * logging in into the system.
 * @type {string}
 * @type {string}
 *
 */
export const LOGIN = "login";
export const LOGIN_OK = "LOGIN_OK";
/**
 *
 * Logout command. Applies to anyone logged into the system.
 * Its responsible for instructing redux to clear local storage.
 * @type {string}
 *
 */
export const LOGOUT = "LOGOUT";
/**
 *
 * Command shows or establishes who is currently logged in
 * @type {string} operator-registration items.
 *
 */
export const OPERATOR = "OPERATOR";
/**
 * Command extracts the token strings from the local storage.
 * Subsequent command also updates auth strings.
 * @type {string}
 * @type {string}
 */
export const AUTH = "AUTH";
export const AUTH_UPD = "AUTH_UPD";
/**
 * Commands for putting or extracting entries from/to local storage.
 * @type {string}
 */
export const ENTRIES = "ENTRIES";
export const MESSAGES = "MESSAGES";
/**
 * These are events that fire when there is a change in state or component
 * @type {string}
 * @type {string}
 *
 */
export let LOCAL_BACKUP = 'LOCAL_BACKUP';
export const NULL_BACKUP = 'NULL_BACKUP';
/**
 *
 * task-related definitions for recording the recordid of the task in question
 *  as well as clearing it once the operation completes
 * @type {string}
 *
 *
 */
export const RECORD_TO_TASK = 'RECORD_TO_TASK';
export const CLEAR_RECORD_TO_TASK = 'CLEAR_RECORD_TO_TASK';
export const TASK_OBJECT = 'TASK_OBJECT';

/**
 *
 * Notifications work
 *
 */
export const CURRENT_NOTIFICATION = 'CURRENT_NOTIFICATION';
export const NULL_CURRENT_NOTIFICATION = 'NULL_CURRENT_NOTIFICATION';
/**
 *
 * These events concern the listing of countries and counties
 * @type {string}
 * @type {string}
 *
 */
export const COUNTRY = 'COUNTRY';
export const COUNTY = 'COUNTY';
/**
 *
 * Events concerning getting gender types from the server
 * @type {string}
 *
 */
export const GENDER = 'GENDER';
/**
 *
 * Events concerning getting game types from server
 * @type {string}
 *
 */
export const GAME = 'GAMES';
/**
 *
 * Events regarding selection formulae used to select winners
 * @type {string}
 *
 */
export const SELECTION_FORMULAE = 'SELECTION_FORMULAE';
/**
 *
 * Events regarding game causes
 * @type {string}
 *
 */
export const GAME_CAUSE = 'GAME_CAUSE';
/**
 *
 * Events regarding game prizes
 * @type {string}
 *
 */
export const GAME_PRIZES = 'GAME_PRIZES';
/**
 *
 * Events regarding license or legal documents type.
 * These will boil down to two main articles: license, permit
 * @type {string}
 *
 */
export const APPLICATION_TYPE = 'APPLICATION_TYPE';
/**
 *
 * reason for license
 * @type {string}
 *
 */
export const REASON_FOR_LICENSE = 'REASON_FOR_LICENSE';

/**
 *
 * Events concerning getting machine types from server
 * @type {string}
 *
 */
export const MACHINE = 'MACHINES';
/**
 *
 * Events concerning banking information. NOTE: BRANCHES can be repurposed
 * for work involving operator-registration branches
 * @type {string}
 *
 */
export const BRANCHES = 'BRANCHES'
export const BANKS = 'BANKS';
//
/**
 *
 * Events regarding permits and licenses.
 * @type {string}
 *
 */
export const LICENSE_PERMITS = 'LICENSE_PERMITS';
/**
 *
 * Events concerning games prize categories. like Jackpot, etc
 * @type {string}
 *
 */
export const GAMES_PRIZE_CATEGORY = 'GAMES_PRIZE_CATEGORY';
/**
 *
 * events regarding operator-registration positions admitted to a given operator-registration
 * @type {string}
 */
export const COMPANY_POSITIONS = 'POSITIONS';
/**
 *
 * events regarding shareholder types
 * @type {string}
 */
export const SHAREHOLDER_TYPE = 'SHAREHOLDER_TYPE';
/**
 *
 * reload instruction
 * @type {string}
 *
 */
export const RELOAD = 'RELOAD_PAGE';

/**
 *
 * String concerns commands on hosting counts of various items. These items are given
 * dynamic names by the user
 * @type {string}
 */
export const COUNTS = 'COUNTS';
/**
 *
 * String concerns command for acquiring and storing payment reasons when a payment is
 * being prescribed by a relevant user in the system.
 * @type {string}
 *
 */
export const PAYMENT_REASONS = 'PAYMENT_REASONS';
/**
 *
 * String concerns command for recording payments categories.
 * @type {string}
 *
 */
export const PAYMENTS = 'PAYMENTS';
// eslint-disable-next-line import/no-anonymous-default-export

/**
 * events regarding different operator-view-sections
 * @type {string}
 */
export const SECTIONS = 'SECTIONS';
/**
 * events regarding people under different operator-view-sections
 * @type {string}
 */
export const PERSONS = 'PERSONS';
export const FEE_PAYMENT_STATUS = 'FEE_PAYMENT_STATUS';
export const FEES = 'FEES';
export const PAYFOR = 'PAY_FOR';


export default {
    BCLB,
    LOGIN,
    LOGOUT,
    OPERATOR,
    AUTH,
    AUTH_UPD,
    ENTRIES,
    MESSAGES,
    LOCAL_BACKUP,
    COUNTY,
    COUNTRY,
    GENDER,
    GAME,
    MACHINE,
    BRANCHES,
    BANKS,
    LICENSE_PERMITS,
    COMPANY_POSITIONS,
    SHAREHOLDER_TYPE,
    GAME_PRIZES,
    GAME_CAUSE,
    SELECTION_FORMULAE,
    APPLICATION_TYPE,
    GAMES_PRIZE_CATEGORY,
    COUNTS,
    SECTIONS,
    PAYMENT_REASONS,
    PAYMENTS,
    FEES,
    RELOAD,
    PERSONS,
    URL,
    TITLE,
    SUBTITLE,
    FEE_TYPE,
    FEE_PAYMENT_STATUS,
    APPLICATION_STATUS,
    RECORD_TO_TASK,
    CLEAR_RECORD_TO_TASK,
    BLUR_FIELDS_FOR,
    PREV_ROUTE,
    NOTIFICATIONS,
    CURRENT_NOTIFICATION,
    NULL_CURRENT_NOTIFICATION,
    PAYFOR,
    ROUTEINDEX,
    VIEWABLE_ITEM,
    DONOTRENEW,
    SUB_NEXT_PAGE,
    SUB_PREVIOUS_PAGE,
    PAGE_PARAMETERS,

    // OPERATOR_ID
}
