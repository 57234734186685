import GMS from "../../../../../parent-component/GMS";
import {col12, col6, row} from "../../../../../../MiscUtils";
import SelectField from "../../../../../general-components/input-field/SelectField";
import {Dispatch} from "../../../../../general-components/redux/app-storage";
import {BRANCHES} from "../../../../../general-components/redux/allowed-actions";
import NumberField from "../../../../../general-components/input-field/NumberField";
import React from "react";

/**
 *
 * Generate dynamic number of field sets
 *
 */
export default class BankFieldSet extends GMS {
    constructor(props) {
        super(props);
        //console.log(props.fieldData)
    }

    UNSAFE_componentWillReceiveProps = (nextProps, nextContext) => {
        // if (nextProps.number)
        // this.setState({dataItem2: nextProps.number}, () => this.generateBankFieldSets());
    }

    /**
     *
     * Pre-create the number of fieldsets for the banks
     *
     *
     */
    componentDidMount = () => {
        // pre-load the branches for the bank in question in order to facilitate
        // default value auto-selection
        if (this.props.fieldData)
            this.sendRequest(
                "get",
                `selections/bankbranch`,
                data => {
                    //console.log('bank branch data', data)
                    if (data.data) {
                        this.setState({dataItem2: [...data.data]});
                    }
                    // else if (Object.getOwnPropertyNames(data.data).includes("notification"))
                    //console.log(data);

                },
                this.props.fieldData['bank']
            );
        this.setState({banks: this.props.banks ? this.props.banks : this.banks});

    }
    /**
     *
     * bank fieldset
     * @returns {JSX.Element}
     *
     */
    bankFieldSet = (count = 1) => {
        return <>
            <div className={row}>
                <div className={col6}
                     style={{display: 'flex', flexDirection: 'row', color: '#800080', fontWeight: 700, fontSize: 14}}>
                    <div>Bank {count} </div>
                    {/*    {this.state.dataItem1.length > 0 &&*/}
                    {/*    <div style={{fontStyle: 'italic'}}> >> <span>{this.state.dataItem1}</span>*/}
                    {/*        {this.state.dataItem3.length > 0 && <span>*/}
                    {/*    ,&ensp;{this.state.dataItem3} branch*/}
                    {/*</span>}*/}
                    {/*    </div>}*/}
                </div>
            </div>
            <div className={row}>
                {/*on selecting bank data, get the bank branches*/}
                <div className={col6}>
                    <SelectField bold
                                 isRequired
                                 fontSize={14}
                                 capitalize
                                 defaultValue={this.props.fieldData && this.props.fieldData['bank']}
                                 options={this.props.banks ? this.props.banks : []} // to be distinguished from banks in state.e
                                 name={'bank'}
                                 placeholder={"bank name"}
                                 changeCallback={bankField => {
                                     this.setState({dataItem1: bankField.target.options[bankField.target.selectedIndex].textContent});
                                     // include it in list of items being sent back
                                     this.props.updateBankData(bankField, count === 0 ? count : count - 1); //do nothing

                                     //console.log(bankField.target.value)
                                     // get bank branches
                                     this.sendRequest(
                                         "get",
                                         `selections/bankbranch`,
                                         data => {
                                             //console.log('bank branch data', data)
                                             if (data.data) {
                                                 //console.log(" bank branch data ", data.data)
                                                 Dispatch({type: BRANCHES, payload: data.data});
                                                 this.setState({dataItem2: [...data.data]});
                                             }
                                             // else if (Object.getOwnPropertyNames(data.data).includes("notification"))
                                             //console.log(data);
                                             // else {
                                             //     //console.log(" bank branch data ", data.data)
                                             //     Dispatch({type: BRANCHES, payload: data.data});
                                             //     this.setState({dataItem2: [...data.data]});
                                             // }

                                         },
                                         bankField.target.value
                                     );
                                 }} //empty callback for now because branches are present
                    />
                </div>
                <div className={col6}>
                    <SelectField bold
                                 isRequired
                                 fontSize={14}
                                 capitalize
                                 defaultValue={this.props.fieldData && this.props.fieldData['bank_branch']}
                                 options={this.state.dataItem2.length > 0 ? this.state.dataItem2 : this.props.branches ? this.props.branches : []}
                        // the given format is wrong! that's why it's failing...
                                 name={"bank_branch"}
                                 placeholder={"branch name"}
                                 changeCallback={d => {
                                     this.setState({dataItem3: d.target.options[d.target.selectedIndex].textContent});
                                     this.props.updateBankData(d, count === 0 ? count : count - 1)
                                 }}
                    />
                </div>
            </div>
            <div className={row}>
                <div className={col12}>
                    {/*crosscheck this with bank branch prefixes to assertain where the bank is.*/}
                    <NumberField
                        bold
                        isRequired
                        fontSize={14}
                        capitalize
                        maxLength={18}
                        //leave this out for security reasons?
                        defaultValue={this.props.fieldData && this.props.fieldData["bank_account"]}
                        changeCallback={d => this.props.updateBankData(d, count === 0 ? count : count - 1)}
                        name={"bank_account"}
                        placeholder={"bank account number"}
                    />
                </div>
            </div>
        </>
    }
    /**
     *
     * Generate bank fieldsets
     *
     */
        // generateBankFieldSets = () => {
        //     let x = 0;
        //     let fieldSets = [];
        //     do {
        //         fieldSets.push(this.bankFieldSet(x + 1));
        //         x += 1;
        //     } while (x < this.state.dataItem2);
        //     this.setState({dataItem5: null}, () => this.setState({dataItem5: [...fieldSets]}));
        // }
    render = () => {
        return this.bankFieldSet(this.props.identifier)

    }
}