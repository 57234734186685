import {connect} from "react-redux";
import GMS from "../../parent-component/GMS";
import {Section, SectionColumn, SectionRow, SectionTitle} from "../view-utils/SectionUtils";
import {Dispatch} from "../../general-components/redux/app-storage";
import {BCLB, NULL_BACKUP, SUBTITLE} from "../../general-components/redux/allowed-actions";
import SelectField from "../../general-components/input-field/SelectField";
import BankFieldSet
    from "../../interfaces/component-input-fields/operator-declaration/operator-collector-sections/micro-components/BankFieldSet";
import {formatDate, notify, readFromLocalStorage} from "../../../MiscUtils";
import TextField from "../../general-components/input-field/TextField";
import SubmitButton from "../../general-components/controls/buttons/SubmitButton";

class DeclarePaymentGateway extends GMS {
    constructor(props) {
        super(props);
        this.banks = readFromLocalStorage('banks', BCLB);
    }

    componentDidMount = () => {
        Dispatch({type: SUBTITLE, payload: 'Declare a payment gateway.'});
        Dispatch({type: NULL_BACKUP});
        // this.sendRequest('get', 'selections/mobile_payment_type_choices', paymentChoices => {
        //     if (paymentChoices.type === 'Success')
        //         this.setState({dataItem1: [...paymentChoices.data]})
        //     else
        //         notify(<span>Cannot get payment choices<br/><br/>
        //             {paymentChoices.message}</span>, 2, false, 'Payment types not available');
        // }, {});
    }
    /**
     *
     * Update bank data
     *
     * @param bankFieldDataReference the field reference
     * @param belongingTo
     */
    updateBankData = (bankFieldDataReference, belongingTo = 0) => {

        let bankDataField = {}; //this is just a pre-construction for new elements within the array
        // check if the current field name exists in state e banks array elements for the belongingTo attribute
        if (this.state.e['banks'])
            if (belongingTo > this.state.e['banks'].length) {
                notify('You must start with the first bank when filling in their field data',
                    2,
                    false,
                    'Bank data sequencing error!');
                return false;
            }
        bankDataField[bankFieldDataReference.target.name] =
            bankFieldDataReference.target.type === "checkbox"
                ? bankFieldDataReference.target.checked
                : bankFieldDataReference.target.type === 'file'
                    ? bankFieldDataReference.target.files[0] : bankFieldDataReference.target.type === 'date' ?
                        formatDate(bankFieldDataReference.target.value, 'ymd', 'dmy', '-')
                        : bankFieldDataReference.target.value;
        // check whether this is the first shareholder
        if (Object.getOwnPropertyNames(this.state.e).includes('banks')) {
            let previousBanks = this.state.e['banks'] === null ? [] : this.state.e['banks'];
            try {
                previousBanks[belongingTo][bankFieldDataReference.target.name] =
                    bankFieldDataReference.target.type === "checkbox"
                        ? bankFieldDataReference.target.checked : bankFieldDataReference.target.type === 'file'
                            ? bankFieldDataReference.target.files[0] : bankFieldDataReference.target.type === 'date' ?
                                formatDate(bankFieldDataReference.target.value, 'ymd', 'dmy', '-')
                                : bankFieldDataReference.target.value;
            } catch (newFieldItemFound) {
                previousBanks.push(bankDataField);
            }
            this.setState(state => {
                // When at least one shareholder exists and that the
                // current shareholder to edit exists within the list
                // filled in so far, update that shareholder's data
                state.e['banks'] = null;
                return state;
            }, () => this.setState(state => {
                state.e['banks'] = previousBanks;
                return state;
            }));
        } else {
            //create a new bank field
            this.setState(state => {
                // ensure that they reside inside an array
                state.e['banks'] = [bankDataField];
                return state;
            }); //, () => //console.log(this.state.e)
        }
        // update data using the props.bufferStateData will be done
    };

    render = () => {
        return <Section>
            {this.state.currentRoute}
            <SectionRow boxedColour>
                <SectionColumn/>
                <SectionColumn>
                    <SelectField name={'gateway'}
                                 bold
                                 isRequired
                                 options={[{1: 'Mobile money'}, {2: 'Bank'}]}
                                 placeholder={'Select type/channel of gateway'}
                        // changeCallback={this.collectFieldData}
                                 changeCallback={e => {
                                     // window.alert(e.target.options[e.target.selectedIndex].value)
                                     // this.setState()
                                     this.collectFieldData([e.target.name, e.target.options[e.target.selectedIndex].value],
                                         true);
                                 }}
                    />
                </SectionColumn>
                <SectionColumn/>
            </SectionRow>

            {
                this.props['gateway'] && this.props['gateway'] === "1" &&
                <SectionRow>
                    <SectionColumn>
                        <SectionTitle>
                            Mobile money
                        </SectionTitle>
                        <SectionRow>
                            <SectionColumn>
                                <SelectField name={'mobile_provider'}
                                             isRequired
                                             bold
                                             fontSize={14}
                                             options={[{1: 'Safaricom'}, {2: 'Airtel'}, {3: 'Telkom'}]}
                                             placeholder={'Select mobile money provider'}
                                             changeCallback={this.collectFieldData}
                                />

                            </SectionColumn>
                            <SectionColumn>
                                <SelectField name={'mobile_channel'}
                                             isRequired
                                             bold
                                             fontSize={14}
                                             options={[
                                                 {1: 'Paybill'},
                                                 // {2: 'buy goods'},
                                                 {2: 'USSD'},
                                                 {2: 'Send money'}
                                             ]}
                                             placeholder={'Type of payment channel'}
                                             changeCallback={(e) => {
                                                 this.collectFieldData(e).then(r => {})
                                             }}
                                />
                            </SectionColumn>
                            <SectionColumn>
                                <TextField name={'account_name'}
                                           isRequired
                                           bold
                                           fontSize={14}
                                           placeholder={'Account number/name'}
                                           changeCallback={this.collectFieldData}
                                />
                            </SectionColumn>

                        </SectionRow>
                        {
                            this.state.e['mobile_channel'] === 1 &&
                            <SectionRow>
                                <SectionColumn>
                                    <SectionTitle>
                                        Type of payment
                                    </SectionTitle>
                                    <SectionRow>
                                        <SectionColumn/>
                                        <SectionColumn>
                                            <SelectField name={'payments_type'}
                                                // options={this.state.dataItem1}
                                                         options={[
                                                             {1: 'B2B'},
                                                             {2: 'B2C'},
                                                             {3: 'C2B'}
                                                         ]}
                                                         placeholder={''}
                                                         changeCallback={
                                                             this.collectFieldData
                                                             // e =>
                                                             //     this.collectFieldData(
                                                             //         [e.target.name, e.target.options[e.target.selectedIndex].value]
                                                             //     )
                                                         }
                                            />
                                        </SectionColumn>
                                        <SectionColumn/>
                                    </SectionRow>
                                </SectionColumn>
                            </SectionRow>
                        }
                    </SectionColumn>
                </SectionRow>
            }
            {
                this.props['gateway'] && this.props['gateway'] === "2" &&
                <SectionRow>
                    <SectionColumn>
                        <SectionRow>
                            <SectionColumn>
                                <SectionTitle>
                                    Declare bank gateway
                                </SectionTitle>
                                <BankFieldSet fieldData={this.props['banks'] && this.props['banks'][0]}
                                              updateBankData={this.updateBankData}
                                              banks={this.banks}
                                    // branches={this.props['branches']}
                                              identifier={1}
                                />
                            </SectionColumn>
                        </SectionRow>
                        {/*<SectionRow>*/}
                        {/*    <SectionColumn>*/}
                        {/*        <SectionTitle>*/}
                        {/*            Type of payment*/}
                        {/*        </SectionTitle>*/}
                        {/*        <SectionRow>*/}
                        {/*            <SectionColumn/>*/}
                        {/*            <SectionColumn>*/}
                        {/*                <SelectField name={'payment_type'}*/}
                        {/*                             options={this.state.dataItem1}*/}
                        {/*                             placeholder={''}*/}
                        {/*                             changeCallback={e => this.collectFieldData([e.target.name, e.target.options[e.target.selectedIndex].value])}/>*/}
                        {/*            </SectionColumn>*/}
                        {/*            <SectionColumn/>*/}
                        {/*        </SectionRow>*/}
                        {/*    </SectionColumn>*/}
                        {/*</SectionRow>*/}
                    </SectionColumn>
                </SectionRow>

            }
            {/*// show only when all fields are present*/}
            {
                // Object.getOwnPropertyNames(this.state.e).length >= 3 && this.state.e['gateway_type'] &&
                <SectionRow>
                    <SectionColumn/>
                    <SectionColumn>
                        <SubmitButton commandText={'Declare'}
                                      callback={() => {
                                          //
                                          const gateway = this.state.e['banks'] ? {
                                                  gateway: parseInt(this.state.e['gateway'])
                                                  , ...this.state.e['banks'][0]
                                                  , payment_type: this.state.e['payment_type']
                                              } :
                                              {
                                                  mobile_provider: parseInt(this.state.e['mobile_provider'])
                                                  , gateway: parseInt(this.state.e['gateway'])
                                                  , mobile_channel: parseInt(this.state.e['mobile_channel'])
                                                  , account_name: this.state.e['account_name']
                                                  , payment_type: this.state.e['payment_type']
                                              }
                                          // console.log(this.state.e)
                                          this.sendRequest('post', 'company/operator/payment-gateway', response => {
                                              if (response.type === 'Success') {
                                                  // notify the navigate to ...list gateways
                                                  notify('Declared 1 gateway', 4, true);
                                                  window.setTimeout(() => this.navigateTo(`../list-gateways/${response.data[0].id}`), 3000);
                                              } else
                                                  notify(<span>
                                                      Failed declaration of gateway. The error reported was:
                                                          <br/>
                                                          <br/>
                                                          {response.data}
                                                  </span>, 2, false, 'Gateway declaration failed'
                                                  );
                                              // }, this.state.e['banks'] ? [...this.state.e['banks']] : this.state.e)
                                          }, gateway);
                                          // make sure the banking information is kept in an array of object with the field name of the array be 'bank'
                                          // send data to backend then navigate to list of payment gateways

                                      }}
                        />
                    </SectionColumn>
                    <SectionColumn/>
                </SectionRow>
            }
        </Section>
    }
}

const stateToProps = initialState => {
    let p = {...initialState.LOCAL_BACKUP};
    return p; // irudishe data
}
export default connect(stateToProps)(DeclarePaymentGateway);