import GMS from "../../../parent-component/GMS";
import {Section, SectionColumn, SectionRow, SectionTitle,} from "../../../rotm-custom-views/view-utils/SectionUtils";
import DynamicTable from "../../../general-components/tables/DynamicTable";
import {Dispatch} from "../../../general-components/redux/app-storage";
import {BCLB, MISC_ID, RELOAD, SUBTITLE} from "../../../general-components/redux/allowed-actions";
import {formatDate, getStorageObject, notify} from "../../../../MiscUtils";

/**
 *
 * Hosts a table for showing list of payments by operators.
 *
 */
export default class ListOfPayments extends GMS {
    constructor(props) {
        super(props);
        this.storage = getStorageObject(BCLB);
        // fetch data
        console.log('constructor ', props.paymentContextId);
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.reload === true) {
            // console.log('next props ', nextProps.paymentContextId);
            this.setState({miscellaneousItems: nextProps.paymentContextId !== undefined ? nextProps.paymentContextId : nextProps.operator}, () => {
                this.fetchListOfPayments(this.state.miscellaneousItems).then(() => this.loadTable(true));
                Dispatch({type: RELOAD, action: false});
            });
        }
    }

    /**
     *
     * Extracts and formats operator fees from operator object and returns and object
     * with the same
     * @param {*} datumFees object containing fee data.
     *
     */
    extractFeeData = (datumFees) => {
        let operatorDatum = {};
        let y = 0
        do {
            operatorDatum["operator_fee_id"] = datumFees[y].id;
            operatorDatum["operator"] = datumFees[y].operator;
            operatorDatum["cheque_no"] = datumFees[y].cheque_no;
            operatorDatum["fee_category"] = datumFees[y].fee_category;
            operatorDatum["local_building_address"] = datumFees[y].local_building_address;
            operatorDatum["local_physical_address"] = datumFees[y].local_physical_address;
            // operatorDatum["fee_category"] = extractValueFromJSON(datumFees[y].fee_category,
            //     arrayOfJSONsToJSON(this.storage.fee_type));
            operatorDatum["date_created"] = formatDate(datumFees[y]['created_date'], 'ymd', 'dmy');
            operatorDatum["bank_statement_no"] = datumFees[y].bank_statement_no;
            operatorDatum["amount"] = datumFees[y].amount;
            operatorDatum["status"] = datumFees[y].status;
            y += 1;
        }
        while (y < datumFees.length)
        return operatorDatum;
    }
    /**
     *
     *
     *
     */
    fetchListOfPayments = async (paymentContextId = null) => {
        this.sendRequest('get', paymentContextId !== null ?
            `finance/potential-payment/?context=${this.props.paymentContext === null ? 'LA' :
                this.props.paymentContext}&context_id=${this.props.paymentContextId !== undefined ? this.props.paymentContextId : this.state.miscellaneousItems}` :
            `finance/potential-payment`, payments => {
            if (payments.type === 'Success') {
                notify(`${payments.data.length} payments retrieved`, 4, true);
                // process them and list them under dataItem1
                let paymentsList = [];
                payments.data.map((payment, index) => {
                    let k = {};
                    k['id'] = payment.id;
                    k['bill_reference'] = payment.billRefNumber;
                    k['payment_for'] = payment.billDesc;
                    k['amount'] = `${payment.currency}.${payment.amountExpected}`;
                    k['service_identifier'] = payment.serviceID;
                    k['status'] = payment.status === false ? 'Not-fulfilled' : 'Fulfilled';
                    k['invoice_number'] = payment.invoice_number.length > 0 ? payment.invoice_number : 'N/A';
                    paymentsList.push(k);
                });
                this.setState({dataItem1: [...paymentsList]});
            }
        }, {});
    }
    /**
     *
     * get data from backend
     *
     */
    componentDidMount = () => {
        Dispatch({type: SUBTITLE, payload: 'List of payments made'});
        let operatorFinanceData = [];
        const paymentContextId = this.props.paymentContextId !== undefined ?
            this.props.paymentContextId : null;
        this.fetchListOfPayments(paymentContextId)
        //
    };
    render = () => {
        //console.log('data item ', this.state.dataItem1);
        return (
            <>
                <Section>
                    <SectionTitle>List of Payments</SectionTitle>
                    {/*<Outlet/>*/}

                    {this.state.currentRoute}
                    <SectionRow>
                        <SectionColumn>
                            <DynamicTable
                                context={'payments'}
                                columnsOfInterest={[
                                    'service_identifier',
                                    'bill_reference',
                                    'invoice_number',
                                    'payment_for',
                                    'amount',
                                    // 'channel',
                                    'status'
                                    // "status"
                                ]}
                                tableData={this.state.dataItem1} //an array of operator_fees payments
                                showRowData={(rowData) => {
                                    Dispatch({type: MISC_ID, payload: rowData});
                                    this.navigateTo('../payment-view', false, {row: rowData})
                                }}
                            />
                        </SectionColumn>
                    </SectionRow>
                </Section>
            </>
        );
    };
}
