
import GMS from "../../parent-component/GMS";
import {Section, SectionColumn, SectionRow, SectionTitle} from "../../rotm-custom-views/view-utils/SectionUtils";
import SimpleLabel from "../labels/SimpleLabel";
import {connect} from "react-redux";
import {Dispatch} from "../redux/app-storage";
import {BCLB, NULL_CURRENT_NOTIFICATION} from "../redux/allowed-actions";
import {readFromLocalStorage} from "../../../MiscUtils";
/**
 *
 * Notification viewer to view notifications
 *
 *
 */
export default class NotificationView extends GMS {
    constructor(props) {
        super(props);
        this.current_notification = readFromLocalStorage('misc_id', BCLB);
    }

    UNSAFE_componentWillReceiveProps = (nextProps, nextContext) => {
        this.setState({randomData: nextProps.notification});
    }

    componentWillUnmount = () => {
        Dispatch({type: NULL_CURRENT_NOTIFICATION});
    }
    /**
     *
     * mark notification as read by simply hitting the endPoint 'notifications/mark-as-read/<notification_id>
     *
     */
    componentDidMount = () => {
        // set state for randomData
        this.setState({randomData: {...this.current_notification}}, () => {
            // mark notification as read
            this.state.randomData !== undefined && this.sendRequest('get', `notifications/mark-as-read/${this.state.randomData.id}`,
                notification => {
                    let readNotification = {...this.state.randomData};
                    // get sender and receiver
                    this.sendRequest('get', `account/user/?email=${this.current_notification.user_from}`, userFrom => {
                        readNotification.user_from = <span>{userFrom.data[0].first_name} {userFrom.data[0].last_name} {userFrom.data[0].surname} <b>[</b>{userFrom.data[0].role.name}<b>]</b></span>;
                        this.sendRequest('get', `account/user/?email=${this.current_notification.user_to}`, userTo => {
                        readNotification.user_to = <span>{userTo.data[0].first_name} {userTo.data[0].last_name} {userTo.data[0].surname} <b>[</b>{userTo.data[0].role.name}<b>]</b></span>;
                            this.setState({randomData: {...readNotification}});
                        }, {});
                    }, {});
                }, {});
        });

    }

    render = () => {
        return this.state.randomData ? <Section>
            <SectionTitle>
                {
                    this.current_notification === undefined ?
                        <>Viewing notification</> :
                        <>Viewing notification {this.current_notification.id}</>
                }
            </SectionTitle>
            <SectionRow boxedColour>
                <SectionColumn>
                    <span>
                        <SimpleLabel text={'Subject matter'} bold capitalize fontSize={16} withColon/>
                    <SimpleLabel
                        text={`${this.state.randomData.topic} No.${this.state.randomData.notification_object_id}`}/>
                    </span>
                </SectionColumn>
                <SectionColumn>
                    <span>
                        <SimpleLabel text={'Sender'} bold capitalize fontSize={16} withColon/>
                    <SimpleLabel
                        text={this.state.randomData.user_from}/>
                    </span>
                </SectionColumn>
                <SectionColumn>
                    <span>
                        <SimpleLabel text={'Receiver'} bold capitalize fontSize={16} withColon/>
                    <SimpleLabel
                        text={this.state.randomData.user_to}/>
                    </span>
                </SectionColumn>
                <SectionColumn>
                    <span>
                        <SimpleLabel text={'Sent on'} bold capitalize fontSize={16} withColon/>
                    <SimpleLabel
                        text={this.state.randomData.date_created}/>
                    </span>
                </SectionColumn>
            </SectionRow>
            <SectionRow boxedColour>
                <SectionColumn>
                    <SimpleLabel
                        text={'Message'} bold withColon fontSize={16}/>
                    <SimpleLabel
                        text={this.state.randomData.text}/>

                </SectionColumn>
            </SectionRow>
            <SectionRow boxedColour>
                <SectionColumn>
                    <span>
                        <SimpleLabel
                            text={'url'} bold withColon fontSize={12}/>
                    <SimpleLabel
                        fontSize={12}
                        text={this.state.randomData.notification_url.length > 0 ? this.state.randomData.notification_url : 'N/A'}/>

                    </span>
                </SectionColumn>
            </SectionRow>
        </Section> : <></>
    }
}

// export default connect(mapStateToProps)(NotificationView);

//
// /**
//  *
//  * Notification viewer to view notifications
//  *
//  *
//  */
// class NotificationView extends GMS {
//     constructor(props) {
//         super(props);
//         this.current_notification = readFromLocalStorage('current_notification', BCLB);
//     }
//
//     UNSAFE_componentWillReceiveProps = (nextProps, nextContext) => {
//         this.setState({miscellaneousItems: nextProps.notification});
//     }
//
//     componentWillUnmount = () => {
//         Dispatch({type: NULL_CURRENT_NOTIFICATION});
//     }
//
//     componentDidMount = () => {
//         this.sendRequest('get', `notifications/mark-as-read/${this.current_notification.id}`, () => {
//             // this is read to true
//         }, {});
//     }
//
//     render = () => {
//         return <Section>
//             <SectionTitle>
//                 {
//                     this.current_notification === undefined ?
//                         <>Viewing notification</> :
//                         <>Viewing notification No. {this.current_notification.id} - {this.current_notification.topic}</>
//                 }
//             </SectionTitle>
//             <SectionRow>
//                 <SectionColumn>
//                     <SimpleLabel/>
//                 </SectionColumn>
//             </SectionRow>
//             <SectionRow>
//                 <SectionColumn>
//                     {this.current_notification.text}
//                 </SectionColumn>
//             </SectionRow>
//         </Section>
//     }
// }
//
// const mapStateToProps = (state, e) => {
//     return {notification: state['current_notification']}
// }
//
// export default connect(mapStateToProps)(NotificationView);