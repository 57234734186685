// old listing
import {Section, SectionColumn, SectionRow, SectionTitle} from "../view-utils/SectionUtils";
import {notify, row} from "../../../MiscUtils";
import GMS from "../../parent-component/GMS";
import LoadingData from "../../general-components/controls/data-loading/LoadingData";
import DynamicTable from "../../general-components/tables/DynamicTable";

/**
 *
 * shows list of reports.
 *
 *
 */
export default class ListFileReturns extends GMS {
    constructor(props) {
        super(props);
        //console.log('list of returns filed ', props.items);
        this.showableFields = {
            'Bookmaking': [
                // 'company_name',
                'sales',
                'payouts',
                'win_loss',
                'net_75',
                'net_withholding',
                'start_date',
                'end_date'
            ],
            'Public Gaming-Online': [
                // 'company_name',
                'sales',
                'payouts',
                'ggr',
                'tax',
                'start_date',
                'end_date'
            ],
            'Public Gaming': [
                // 'company_name',
                'tables',
                'slots',
                'total',
                'gaming_tax',
                'start_date',
                'end_date'
            ],
            'Public Lottery': [
                'license_type',
                'online',
                'sales',
                'payouts',
                'date_start',
                'date_end',
            ],
            'Prize Competition': [
                'company_name',
                'winner_name',
                'phone_number',
                'id_passport',
                'location',
                'prize',
                'witness',
                'start_date',
            ],
        };
    }

    componentDidMount = () => {
        this.sendRequest('get', 'company/operator/returns', returnsList => {
            if (returnsList.type === 'Success') {
                let k = 0;
                let f = returnsList.data.length;
                let p = [];
                // go through each piece of data and classify the records
                // according to gameType
                let classifiedGameReturns = {};
                this.setState({dataItem1: [...returnsList.data]})

                // do {
                //     let p = [];
                //     if (!Object.getOwnPropertyNames(classifiedGameReturns).includes(returnsList.data[k].license_type))
                //         // create an array whose key is the current license type
                //     {
                //         p.push(returnsList.data[k]);
                //         classifiedGameReturns[returnsList.data[k].license_type] = [...p];
                //     } else {
                //         p.push(returnsList.data[k]);
                //         classifiedGameReturns[returnsList.data[k].license_type] = [...classifiedGameReturns[returnsList.data[k]], ...p]
                //     }
                //     // create an object with all the items therein, add whether the
                //     k += 1;
                // } while (k < f);

                this.setState({dataItem1: [...returnsList.data]});
            } else notify('No returns filed for your company',
                3,
                false,
                'Returns not found');
        }, {});
    }

    render = () => {
        return <Section noBoxShadow boxedColour>
            <SectionTitle>
                Filed Returns
            </SectionTitle>
            <SectionRow>
                <SectionColumn>
                    {
                        this.state.dataItem1.length === 0 ?
                            <LoadingData hasData={this.state.dataItem1.length === 0}
                                         message={'Retrieving filed returns'}/> :
                            // for each return item, display it in its own table
                            // this.state.dataItem2
                            <DynamicTable context={'Returns list'}
                                          tableData={this.state.dataItem1}
                                          columnsOfInterest={[
                                              'license_type',
                                              'online',
                                              'sales',
                                              'payouts',
                                              'date_start',
                                              'date_end']}
                            />
                    }
                </SectionColumn>
            </SectionRow>
            {/*<div className={`${row} bottom-streak`} style={{height: 4, background: '#800080'}}/>*/}
            <div className={`${row} bottom-streak`} style={{height: 4, background: '#59788E'}}/>
            {/*{this.reportListView()}*/}
        </Section>
    }
}
/////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////
/////////////////////////////multiple tables for returns/////////////////////
/////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////
//// new listing
//
// import {Section, SectionColumn, SectionRow, SectionTitle} from "../view-utils/SectionUtils";
// import {notify, row} from "../../../MiscUtils";
// import GMS from "../../parent-component/GMS";
// import LoadingData from "../../general-components/controls/data-loading/LoadingData";
// import DynamicTable from "../../general-components/tables/DynamicTable";
// import NothingFound from "../../general-components/labels/NothingFound";

// /**
//  *
//  * shows list of reports.
//  *
//  *
//  */
// export default class ListFileReturns extends GMS {
//     constructor(props) {
//         super(props);
//         //console.log('list of returns filed ', props.items);
//         this.showableFields = {
//             'Bookmaking': [
//                 // 'company_name',
//                 'sales',
//                 'payouts',
//                 'win_loss',
//                 'net_75',
//                 'net_withholding',
//                 'start_date',
//                 'end_date'
//             ],
//             'Public Gaming-Online': [
//                 // 'company_name',
//                 'sales',
//                 'payouts',
//                 'ggr',
//                 'tax',
//                 'start_date',
//                 'end_date'
//             ],
//             'Public Gaming': [
//                 // 'company_name',
//                 'tables',
//                 'slots',
//                 'total',
//                 'gaming_tax',
//                 'start_date',
//                 'end_date'
//             ],
//             'Public Lottery (Short Term)': [
//                 'license_type',
//                 'online',
//                 'sales',
//                 'payouts',
//                 'date_start',
//                 'date_end',
//             ],
//             'Public Lottery (Long Term)': [
//                 'license_type',
//                 'online',
//                 'sales',
//                 'payouts',
//                 'date_start',
//                 'date_end',
//             ],
//             'Prize Competition': [
//                 'company_name',
//                 'winner_name',
//                 'phone_number',
//                 'id_passport',
//                 'location',
//                 'prize',
//                 'witness',
//                 'start_date',
//             ],
//         };
//     }
//
//     componentDidMount = () => {
//         this.sendRequest(
//             'get',
//             'company/operator/returns',
//             returnsList => {
//                 if (returnsList.type === 'Success') {
//                     let k = 0;
//                     let f = returnsList.data.length;
//                     let p = [];
//                     // go through each piece of data and classify the records
//                     // according to gameType
//                     let classifiedGameReturns = {};
//                     let returnsTables = [];
//
//                     do {
//                         let p = [];
//                         // console.log(Object.getOwnPropertyNames(classifiedGameReturns), !Object.getOwnPropertyNames(classifiedGameReturns).includes(returnsList.data[k].license_type))
//                         if (!Object.getOwnPropertyNames(classifiedGameReturns).includes(returnsList.data[k].license_type))
//                             // create an array whose key is the current license type
//                         {
//                             // p.push(returnsList.data[k]);
//                             classifiedGameReturns[returnsList.data[k].license_type] = [returnsList.data[k]];
//                             console.log(' new item ', classifiedGameReturns[returnsList.data[k].license_type])
//                         } else {
//                             p.push(returnsList.data[k]);
//                             classifiedGameReturns[returnsList.data[k].license_type] = [...classifiedGameReturns[returnsList.data[k]], ...p]
//                         }
//                         // create a dynamic table with these attributes loaded and set.
//                         // create an object with all the items therein, add whether the
//                         k += 1;
//                     } while (k < f);
//                     k = 0;
//                     const y = Object.getOwnPropertyNames(classifiedGameReturns);
//                     do {
//                         returnsTables.push(<DynamicTable context={'Returns list'}
//                                                          tableData={classifiedGameReturns[y[k]]}
//                                                          columnsOfInterest={[this.showableFields[y[k]]]}
//                         />)
//                         k += 1;
//                     } while (k < y.length)
//                     // this.setState({dataItem1: [...returnsList.data]});
//                     this.setState({dataItem2: [...returnsTables]});
//                 } else {
//                     notify('No returns filed for your company',
//                         3,
//                         false,
//                         'Returns not found');
//                     this.setState({dataItem2: <NothingFound
//                             message={'No returns filed/found'}/>});
//                 }
//
//             }, {});
//     }
//
//
//     render = () => {
//         return <Section noBoxShadow boxedColour>
//             <SectionTitle>
//                 Filed Returns
//             </SectionTitle>
//             <SectionRow>
//                 <SectionColumn>
//                     {
//                         this.state.dataItem1.length === 0 &&
//                         this.state.dataItem2.constructor.name === [].constructor.name ?
//                             <LoadingData hasData={this.state.dataItem1.length === 0}
//                                          message={'Retrieving filed returns'}/> :
//                             // for each return item, display it in its own table
//                             this.state.dataItem2
//                     }
//                 </SectionColumn>
//             </SectionRow>
//             {/*<div className={`${row} bottom-streak`} style={{height: 4, background: '#800080'}}/>*/}
//             <div className={`${row} bottom-streak`} style={{height: 4, background: '#59788E'}}/>
//             {/*{this.reportListView()}*/}
//         </Section>
//     }
// }