/**
 *
 * A component to simply display that nothing was found.
 * This is useful especially in DynamicTable or a display which renders then waits for
 * data to arrive from the backend
 *
 */
import React, {Component} from "react";
import {SectionColumn, SectionRow} from "../../rotm-custom-views/view-utils/SectionUtils";

export default class NothingFound extends Component {
    constructor(props) {
        super(props);
    }

    render = () => {
        return <SectionRow>
            <SectionColumn style={{
                fontFamily: 'monospace',
                fontSize: 28,
                fontWeight: 800,
                color: '#c5c6d5',
                textAlign: 'center'
            }}>
                {this.props.message ? this.props.message : 'No data found!'}
            </SectionColumn>
        </SectionRow>
    }
}