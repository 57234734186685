/**
 *
 * Componet is responsible for showing all payments ever made by an operator-registration. This component will be used heavily
 * by the finance section.
 * It will show:
 * OPTIONAL-PRIVILEGED: [operator-registration payments (for licenses or others relative to the board's requirements)]
 *
 *
 */
import GMS from "../../../parent-component/GMS";
import {Section, SectionColumn, SectionRow, SectionTitle} from "../../view-utils/SectionUtils";
import React from "react";
import {MISC_ID, TASK_OBJECT} from "../../../general-components/redux/allowed-actions";
import {Dispatch} from "../../../general-components/redux/app-storage";
import NothingFound from "../../../general-components/labels/NothingFound";

export default class ListOfPayments extends GMS {
    constructor(props) {
        super(props);
        // fields to calculate total payments made
        this.totalPayments = 0;
        this.currency = null;
    }

    UNSAFE_componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any) {
        if (nextProps.payments && nextProps.payments.length > 0) {
            this.setState({dataItem1: [...nextProps.payments]});
        }
    }

    componentDidMount = () => {
        // get payment information on a specific operator-registration
        if (this.props.payments && this.props.payments.length > 0) {
            this.setState({dataItem1: [...this.props.payments]}, () => this.setState({dataItem3: [...this.payments()]}));
        }
    }
    payments = () => {
        let y = 0;
        let t = [];
        this.state.dataItem1.map((payment, index) => {
            this.totalPayments += payment.status ? parseInt(payment['amountExpected']) : 0;
            if (this.currency === null)
                this.currency = payment['currency']
            t.push(
                // <Section>
                <SectionRow>
                    <SectionColumn>
                        <span>
                            {/*payment {y + 1}*/}
                            {
                                payment['status'] === true ?
                                    // <span style={{fontWeight: 500, color: '#4de823'}}>
                                    <span style={{fontFamily: 'sans-serif,serif', fontWeight: 500, color: '#2c9a0a'}}>
                                       <b>{payment['billRefNumber']}</b>
                                    </span> :
                                    <span style={{
                                        // fontFamily: 'sans-serif, serif',
                                        fontStyle: 'italic',
                                        fontWeight: 300,
                                        color: '#f11111',
                                        fontSize: '0.9em'
                                    }}>
                                        <b>{payment['billRefNumber']}</b>
                                    </span>
                            }
                            &nbsp;
                            - {
                            payment['currency']}. {payment['amountExpected']
                        } payment for
                            &nbsp;
                            <span style={{fontWeight: 500}}>{payment['billDesc']}</span>
                            &nbsp;
                            {/*on*/}
                            {/*&nbsp;*/}
                            {/*<span style={{fontWeight: 500}}>{this.state.dataItem1[y]['billDesc']}</span>*/}


                        </span>
                    </SectionColumn>
                    <SectionColumn>
                        {
                            // payment.status === true &&
                            payment.status === false && <a style={{cursor: 'pointer', color: '#5473da'}} onClick={e => {
                                // write payment data to misc_id
                                Dispatch({type: MISC_ID, payload: payment});
                                Dispatch({type: TASK_OBJECT, payload: 'invoice'});
                                this.navigateTo('../pdf-view');
                                // navigate to the component
                                // }}>Download {payment.status === false ? 'invoice' : 'receipt'}</a>
                            }}>Download invoice</a>
                        }
                    </SectionColumn>
                </SectionRow>
                // </Section>
            );
        })
        // do {
        //
        //     y += 1;
        // } while (y < this.state.dataItem1.length);
        return t;
    }
    render = () => {
        return <Section>
            {this.state.currentRoute}
            <SectionTitle>
                Payments
            </SectionTitle>
            <SectionRow>
                <SectionColumn>
                    <span><b>Total:</b>&nbsp;
                        <span style={{
                            // margin: 4,
                            padding: 1.4,
                            borderBottom: '2px solid #2e38f8',
                            // border: '1PX solid #2e38f8', //0.125rem
                            // borderRadius: 4,
                            fontWeight: 500,
                            color: '#1c8103'
                        }}> {this.currency}. {this.totalPayments}
                        </span>
                    </span>
                </SectionColumn>
            </SectionRow>
            {/*// check random data for data from server. if there's none, */}
            {
                this.state.dataItem3.length > 0 ? this.state.dataItem3 : <NothingFound message={'no data on payments'} />
            }
        </Section>

    }
}