import GMS from "../../parent-component/GMS";
import {connect} from "react-redux";
import {Dispatch} from "../../general-components/redux/app-storage";
import {
    BCLB,
    MISC_ID,
    PAGE_PARAMETERS,
    SUB_NEXT_PAGE,
    SUB_PREVIOUS_PAGE,
    SUBTITLE,
    TASK_OBJECT
} from "../../general-components/redux/allowed-actions";
import React from "react";
import DynamicTable from "../../general-components/tables/DynamicTable";
import SelectField from "../../general-components/input-field/SelectField";
import SimpleLabel from "../../general-components/labels/SimpleLabel";
import {notify, readFromLocalStorage} from "../../../MiscUtils";
import {Section, SectionColumn, SectionRow} from "../../rotm-custom-views/view-utils/SectionUtils";

/**
 *
 * Class retrieves paginated content from backend and offers it for display.
 * Categorization of records is henceforth impossible given the dynamic nature
 * of pagination.
 *
 */
class PaginatedPremises extends GMS {
    constructor(props) {
        super(props);
        // const licenseId = readFromLocalStorage('misc_id', BCLB);
        this.endPoint = `company/operator/premise`;

    }

    /**
     *
     * Receive props from redux on change
     *
     * @param nextProps
     * @param nextContext
     * @constructor
     */
    UNSAFE_componentWillReceiveProps = (nextProps, nextContext) => {
        const subTasksPages = `${nextProps.endPoint}&page=1`;
        nextProps.next_page && this.setState({variable: `${subTasksPages}&page=${nextProps.next_page}`});
        nextProps.previous_page && this.setState({visible: `${subTasksPages}&page=${nextProps.previous_page}`});
        nextProps.page_parameters !== null && this.setState({dataItem4: nextProps.page_parameters});
    }

    /**
     *
     * Fetch pages from backend using the endpoint dictated in
     * this.state.variable
     *
     * @param page
     *
     * @param parameters
     */
    getData = (page = this.state.variable === null ? this.endPoint : this.state.variable, parameters = this.state.dataItem4.length > 0 ? this.state.dataItem4 : null) => {
        let paginatedURL = this.state.dataItem2 === 1 ? parameters !== null ?
            `${this.endPoint}&page=${page}&${parameters}` : `${this.endPoint}&page=${page}&${parameters}` : parameters !== null ?
            `${this.endPoint}&${parameters}` : this.endPoint;
        // let paginatedURL = this.state.dataItem2 === 1 ?
        this.sendRequest('get',
            paginatedURL,
            pageData => {
                if (pageData.type === 'Success') {
                    // update the page in redux
                    pageData.previous !== null && Dispatch({type: SUB_PREVIOUS_PAGE, payload: pageData.previous});
                    pageData.next !== null && Dispatch({type: SUB_NEXT_PAGE, payload: pageData.next});
                    parameters !== null && Dispatch({type: PAGE_PARAMETERS, payload: parameters});
                    // fetch results field
                    this.setState({
                        dataItem1: [...pageData.data],
                        visible: pageData.previous,
                        variable: pageData.next,
                        dataItem4: parameters,
                        dataItem2: 1
                    });
                } else
                    notify(<span>returns records could not be retrieved<br/><br/>
                        {pageData.message}
                </span>, 2, false, pageData.title);
            }, {})
    }
    componentDidMount = () => {
        this.setState({
            // dataItem3: [...initialFilterCondition],
            variable: this.state.variable === null ? this.endPoint : this.state.variable,
        }, this.getData);
    }
    render = () => {
        return <Section>
            {this.state.currentRoute}
            <SectionRow>
                <SectionColumn>
                    <SimpleLabel text={'Returns filed under this license'} bold fontSize={18}/>
                </SectionColumn>
            </SectionRow>
            <SectionRow>
                <SectionColumn>
                    <DynamicTable
                        filterFunction={(filterBy, searchParam) => {
                            this.sendRequest('get',
                                // `tasks/tasks&${filterBy}=${searchParam}`,
                                searchParam.length > 0 ?
                                    `${this.endPoint}&${filterBy}=${searchParam}` :
                                    `${this.endPoint}&page=1`,
                                tasks => {
                                    if (tasks.data.length > 0)
                                        this.setState({dataItem1: [...tasks.data]})
                                    else
                                        notify(`No records on ${searchParam}`, 3, true)
                                }, {});

                        }}
                        enableFilterBy={'search'}
                        pageSize={24}
                        paraphrase={60}
                        context={'Premise list'}
                        tableData={this.state.dataItem1} //an array of tasks-tool
                        columnsOfInterest={[
                            'id',
                            'company_name',
                            'name',
                            'building',
                            'county',
                            'office_type',
                            // 'area',
                            'next_payment',
                            // 'is_franchise',
                            'application_status',
                            'status',
                        ]}
                        showRowData={premiseFile => {
                            Dispatch({
                                type: SUBTITLE,
                                payload: `Viewing premise file No.${premiseFile.id}`
                            });
                            Dispatch({type: MISC_ID, payload: premiseFile})
                            // Dispatch({type: TASK_OBJECT, payload: 1})
                            // this.navigateTo(`../operator-view/${operatorData.operator_name}`, false)
                            this.navigateTo(`../premise-view/${premiseFile.office_type}/${premiseFile.county}`, false)
                        }}
                        // details should be paraphrased
                    />
                </SectionColumn>
            </SectionRow>
            {this.state.dataItem1.length > 0 && <SectionRow>
                <SectionColumn>
                    <span>
                        <SelectField name={'page_size'}
                                     commandText={'select page size'}
                                     placeholder={'Page size'}
                                     bold
                                     fontSize={14}
                                     defaultValue={10}
                                     options={[{5: 5}, {10: 10}, {20: 20}, {50: 50}]}
                                     changeCallback={e => {
                                         this.getData(1,
                                             `page_size=${e.target.options[e.target.selectedIndex].value}`)
                                     }}
                        />
                        {
                            this.state.visible &&
                            <a href={'#'}
                               onClick={() => this.getData(this.state.visible)}>
                                <b>Previous</b>
                            </a>
                        }
                        &ensp;
                        {
                            this.state.variable && <span>
                            Page&ensp;{
                                this.state.variable - 1
                            }
                        </span>
                        }
                        &ensp;
                        {
                            this.state.variable &&
                            <a href={'#'} onClick={() => this.getData(this.state.variable)}><b>Next</b></a>
                        }
                    </span>
                </SectionColumn>
                <SectionColumn/>
                <SectionColumn/>
                <SectionColumn/>
            </SectionRow>}
        </Section>
    }
}

const stateToProps = initialState => {
    let p = {};
    p['previous_page'] = initialState.sub_previous_page;
    p['next_page'] = initialState.sub_next_page;
    p['page_parameters'] = initialState.page_parameters;
    return p;
}

export default connect(stateToProps)(PaginatedPremises);