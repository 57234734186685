import React from "react";
import GMS from "../../../../parent-component/GMS";
import {Section, SectionRow, SectionTitle,} from "../../../../rotm-custom-views/view-utils/SectionUtils";
import {connect} from "react-redux";
import {MISC_ID,} from "../../../../general-components/redux/allowed-actions";
import {Dispatch} from "../../../../general-components/redux/app-storage";
import DynamicTable from "../../../../general-components/tables/DynamicTable";
import {notify} from "../../../../../MiscUtils";
import LoadingData from "../../../../general-components/controls/data-loading/LoadingData";

class PremiseList extends GMS {
    constructor(props) {
        super(props);
    }

    componentDidMount = () => {
        // Dispatch({type:SUBTITLE,payload:'View list of premises'});
        // This is a necesary work-around
        this.sendRequest('get', 'company/operator/premise/?page_size=150', premises => {
            this.setState({visible: true});
            if (premises.type === 'Success') {
                // append them to a table
                if (premises.data.length > 0)
                    this.setState({dataItem4: [...premises.data]});
            } else
                notify('Could not get list of premises', 3, true);
        }, {});
    };

    render = () => {
        return (
            <Section>
                <SectionTitle>
                    <span>List Of Premises</span>
                </SectionTitle>
                {this.state.currentRoute}
                <SectionRow>
                    {
                        !this.state.visible ?
                            <LoadingData hasData={this.state.dataItem4.length === 0}
                                         message={'Getting list of premises...'}
                            /> :
                            <DynamicTable
                                noSearch
                                pageSize={40}
                                context={'premises - including offices and business'}
                                tableData={this.state.dataItem4}
                                columnsOfInterest={[
                                    'id',
                                    'office_type',
                                    "county",
                                    'building'
                                    // "plot name",
                                ]}
                                showRowData={rowData => {
                                    // navigate to the premise-view
                                    Dispatch({type: MISC_ID, payload: rowData});
                                    this.navigateTo("../premise-view", false, {row: rowData});
                                }}
                            />
                    }

                </SectionRow>
            </Section>
        );
    };
}

const mapStateToProps = (storage) => {
    return storage.LOCAL_BACKUP;
};

export default connect(mapStateToProps)(PremiseList);
